import React from 'react'
import abtbanner from '../assets/bluetik_mobile/abtbannermb.png';
import elegancemb from '../assets/bluetik_mobile/elegancemb.png';
import misionmb from '../assets/bluetik_mobile/misinmb.svg';
import visonnmb from '../assets/bluetik_mobile/visionmb.svg';
import valuesmb from '../assets/bluetik_mobile/valuesmb.svg';
import visiongardient from '../assets/bluetik_mobile/visiongardient.svg';
import bgmaruthu from '../assets/bluetik_mobile/maruthubg1.png';
import contactbg1 from '../assets/bluetik_mobile/contactbgmb.png';
import bluearrow from '../assets/bluearrow.svg'
import { FooterMobile } from './footerMobile';
import NavbarMobile from './navbarMobile';
import whatsappIcon from '../assets/bluetik_mobile/whatsapp-icon.svg';  // Import WhatsApp icon image
import instaIcon from '../assets/insta_icon.svg';
import emailIcon from '../assets/email_icon.svg';
export const AboutusMobile = () => {
    const bg1 = {
        backgroundImage: `url(${bgmaruthu})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    const contactbg = {
        backgroundImage: `url(${contactbg1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    return (
        <div className='bg-black w-full h-[4747px] mobile-only'>
            <div>
                <NavbarMobile />
            </div>
            <div className='text-[35px] text-white Gilroy-Medium flex text-center items-center justify-center pt-[123px]'>Our Story</div>
            <div className='text-[18px] Gilroy-Light text-[#0CC0DB] flex items-center justify-center mt-[12px]'>upholding the essence of interior design</div>
            <img src={abtbanner} className='flex items-center justify-center mx-auto mt-[27px] w-[98%] ' />
            <div className='text-[27px] Gilroy-Medium text-[#ffffff] flex items-center justify-center mx-auto text-center leading-[34px] mt-[34px]'>Experience Bluetik Interiors - Where Dreams Take Shape</div>
            <div className='text-center text-[#A1A1A1] w-[322px] flex mx-auto leading-[24px] mt-[17px]'>At Bluetik Interiors, we see your home as a reflection of you. With our passion for design and commitment to excellence, we aim to exceed your expectations and create a masterpiece that speaks to your soul.</div>
            <div className='w-[138px] h-[2px] bg-white flex mx-auto mt-[41px]'></div>
            <div className='text-[27px] Gilroy-Regular text-[#A8A8A8] ml-[21px] mt-[81px]'>Experience the pinnacle<br></br> of elegance</div>
            <img src={elegancemb} className='ml-[21px] mt-[17px] w-[351px] h-[381px]' />
            <div className='text-[28px] Gilroy-Medium text-[#ffffff] ml-[21px] mt-[44px]'>Project Management</div>
            <div className='text-[18px] Gilroy-Regular text-[#888888] ml-[21px] mt-[14px] w-[353px]'>We seamlessly navigate complexities, delivering flawless results on time, every time. Entrust your project to us, where effective management ensures excellence, you can rest assured that your project will be completed on time and within budget.</div>
            <div className='text-[28px] Gilroy-Medium linear-creating text-center mt-[121px]'>Creating the Art of <br></br>Stylish Living</div>

            <div className=''>
                <img src={visonnmb} className='flex mx-auto mt-[58px]' />
                <div className='text-[15px] Gilroy-Regular text-[#888888]  mt-[44px] flex items-center justify-center mx-auto text-center'>At Bluetik Interiors, our vision is to redefine the<br></br> concept of living by creating immersive environments that resonate with individuality and elegance.</div>
                <img src={visiongardient} className=' mt-[44px] ' />

                <img src={misionmb} className='flex mx-auto mt-[58px]' />
                <div className='text-[15px] Gilroy-Regular text-[#888888]  mt-[44px] flex items-center justify-center mx-auto text-center'>Our mission is to blend innovation and functionality to<br></br>  curate spaces that enrich lives and inspire experiences, one design at a time.</div>
                <img src={visiongardient} className=' mt-[44px] ' />

                <img src={valuesmb} className='flex mx-auto mt-[58px]' />
                <div className='text-[15px] Gilroy-Regular text-[#888888]  mt-[44px] flex items-center justify-center mx-auto text-center'>At Bluetik Interiors, integrity, creativity, collaboration,<br></br> and excellence are not just words; they are the pillars<br></br> upon which we build lasting relationships and<br></br> exceptional spaces.</div>
                <img src={visiongardient} className=' mt-[44px] ' />
            </div>
            {/* New WhatsApp Chat Button */}
            <div style={{ position: 'fixed', bottom: '310px', leftt: '20px', marginLeft: '47px', marginTop: '-147px' }}>
                <div className='flex items-center p-2 rounded-full shadow-lg' style={{
                    background: 'rgba(0, 0, 0, 0.19)',
                    backdropFilter: 'blur(1.808px)',
                    webkitBackdropFilter: 'blur(10px)',
                    borderRadius: '42px',
                    padding: '10px'
                }}>
                    <a href="https://wa.me/7619691418 " target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                        <img src={whatsappIcon} alt="WhatsApp" className='w-6 h-6' />
                    </a>
                    <a href="https://www.instagram.com/blue_tik_interiors/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                        <img src={instaIcon} alt="Instagram" className='w-6 h-6' />
                    </a>
                    <a href="mailto:bluetikinteriors@gmail.com" target="_blank" rel="noopener noreferrer" className='flex items-center'>
                        <img src={emailIcon} alt="Email" className='w-6 h-6' />
                    </a>
                </div>
            </div>
            <div className='min-h-screen flex items-center justify-center' style={bg1}>
                <div className='w-[521px] h-[754px] flex flex-col mx-auto items-center justify-center'>
                    <div className='text-[18px] Gilroy-Medium text-[#ffffff] text-center mb-4 mt-[474px]'>
                        Bluetik embodies innovation, creativity,<br /> and unparalleled craftsmanship in design.<br /> Explore our world, where inspiration meets<br /> execution, and dreams come alive
                    </div>
                    <div className='text-[18px] Gilroy-Medium text-[#0CC0DB]'>
                        - Maruthu
                    </div>
                </div>
            </div>
            <div className='contact w-full h-[352px]' style={contactbg}>
                <div className='text-[30px] Gilroy-Medium text-[#ffffff] text-center pt-[91px]'> Contact us </div>
                <div className='text-[24px] Gilroy-Regular text-[#ffffff] flex text-center'>today to discover the endless possibilities for your space.</div>
                <div className='flex mx-auto items-center justify-center mt-[27px]'>
                    <div className='w-[147px] h-[38px] text-[13px] bg-white rounded-[27px] flex items-center mx-auto justify-center Gilroy-Regular text-black'>Schedule a Call<span><img src={bluearrow} className='ml-[8px] w-[8px] h-[8px]' /></span></div>
                </div>
            </div>

            <div className='bg-[#0C0C0C] mt-[-123px]'>
                <FooterMobile />
            </div>
        </div>
    )
}
