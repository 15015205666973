import React from 'react';
import abtbanner from '../assets/interirors.png';
import projectmanage from '../assets/project.png';
import bgcreating from '../assets/vision_mission.png';
import bgvce from '../assets/voiceimage_new.png';
import discbg from '../assets/discover_bg.png';
import creating from '../assets/creating-arts.png';
import Navbar from './navbar';
import Footer from './footer';
import storyline from '../assets/story_line.png';
import bluearrow from '../assets/blue_arrows.svg'
import whatsappIcon from '../assets/bluetik_mobile/whatsapp-icon.svg';  // Import WhatsApp icon image
import instaIcon from '../assets/insta_icon.svg';
import emailIcon from '../assets/email_icon.svg';
import { Link } from 'react-router-dom';
const Aboutus = () => {
    const bgart = {
        backgroundImage: `url(${bgcreating})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    const bgvoice = {
        backgroundImage: `url(${bgvce})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    const discover = {
        backgroundImage: `url(${discbg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    return (
        <div className=' hide-on-mobile'>

            <div className='bg-[#0C0C0C] w-full h-[2844px]'>
                <Navbar />
                <div className='text-[84px] text-right text-white mr-[82px] Gilroy-Medium pt-[214px]'>Our Story</div>
                <div className='flex items-center justify-end text-[24px] text-right text-[#0CC0DB] mr-[82px] Gilroy-Light'>
                    <span className='mr-[8px]'>
                        <img src={storyline} alt="Storyline Image" />
                    </span>
                    <span>upholding the essence of interior design</span>
                </div>
                <div className='flex items-center justify-center mx-auto mt-[72px]'>
                    <img src={abtbanner} />
                </div>
                <div className='text-[50px] text-white flex items-center justify-center mx-auto mt-[70px] Gilroy-Medium'>Experience Bluetik Interiors - Where Dreams Take Shape</div>
                <div className='text-[30px] text-[#A1A1A1] text-center w-[1120px] flex items-center justify-center mx-auto leading-[37px] mt-[15px] Gilroy-Regular'>At Bluetik Interiors, we see your home as a reflection of you. With our passion for design and commitment to excellence, we aim to exceed your expectations and create a masterpiece that speaks to your soul.</div>
                <div className='w-[277px] h-[2px] flex mx-auto bg-white mt-[121px]'></div>
                <div className='text-[48px] text-[#A8A8A8] ml-[150px] mt-[98px] Gilroy-Regular'>
                    Experience the pinnacle
                </div>
                <div className='text-[48px] text-[#A8A8A8] ml-[410px] textt-right Gilroy-Regular'>
                    of elegance </div>
                <div className='flex flex-row'>
                    <div className='flex flex-col w-[50%] '>
                        <img src={projectmanage} className='w-[753px] h-[741px] ml-[150px] mt-[19px]' />
                    </div>
                    <div className='flex flex-col w-[50%] h-[741px] '>
                        <div className='text-[41px] text-white pt-[429px] Gilroy-Medium'>Project Management</div>
                        <div className='text-[30px] text-[#888888] leading-[39px] w-[792px] Gilroy-Regular'>We seamlessly navigate complexities, delivering flawless results on time, every time. Entrust your project to us, where effective management ensures excellence, you can rest assured that your project will be completed on time and within budget.</div>
                    </div>
                </div>
            </div>

            <div style={{ position: 'fixed', bottom: '80px', leftt: '20px', marginLeft: '47px', marginTop: '147px' }}>
                <div className='flex items-center p-2 rounded-full shadow-lg' style={{
                    background: 'rgba(0, 0, 0, 0.19)',
                    backdropFilter: 'blur(1.808px)',
                    webkitBackdropFilter: 'blur(10px)',
                    borderRadius: '42px',
                    padding: '10px'
                }}>
                    <a href="https://wa.me/7619691418" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                        <img src={whatsappIcon} alt="WhatsApp" className='w-6 h-6' />
                    </a>
                    <a href="https://www.instagram.com/blue_tik_interiors/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                        <img src={instaIcon} alt="Instagram" className='w-6 h-6' />
                    </a>
                    <a href="mailto:bluetikinteriors@gmail.com" target="_blank" rel="noopener noreferrer" className='flex items-center'>
                        <img src={emailIcon} alt="Email" className='w-6 h-6' />
                    </a>
                </div>
            </div>
            {/* <div className='creating h-[422px] bg-black' style={bgart}>
                <div className='flex flex-row'>
                    <div className='flex flex-col w-[50%] h-[184px] '>
                        <div className='text-white text-[24px] text-center'>Vision</div>
                        <div className='text-[15px] text-[#888888] w-[399px] ml-[214px]'>At Bluetik Interiors, our vision is to redefine the concept of living by creating immersive environments that resonate with individuality and elegance.</div>
                    </div>
                    <div className='flex flex-col w-[50%] h-[184px] '>
                        <div className='text-[#0CC0DB] text-[51px] leading-[59px]'>Creating the Art of<br /> Stylish Living</div>
                    </div>
                </div>
            </div> */}
            <div className='bg-[#0D0D0D] pt-[82px] pb-[82px]'>
                <img src={creating} className='w-[1598px] h-[744px] flex items-center justify-center mx-auto' />
            </div>

            <div className='bg-[#0D0D0D] pt-[77px] pb-[77px]'>
                <div className='w-[1500px] h-[712px] flex items-center jusify-center mx-auto' style={bgvoice}>
                    <div className='w-[773px] h-[114px] text-[27px] leading-[38px] text-[#EAEAEA] text-right ml-[600px] pt-[85px]  Gilroy-Medium'>Bluetik embodies innovation, creativity, and unparalleled craftsmanship in design. Explore our world, where inspiration meets execution, and dreams come alive</div>
                </div>
            </div>

            <div className='w-full h-[472px]' style={discover}>
                <div className='text-white text-[37px] text-center pt-[135px] Gilroy-Medium'>
                    Contact us
                </div>
                <div className='text-white text-center text-[27px] Gilroy-Regular'>today to discover the endless possibilities for your space.</div>
                <Link to="/contactus"><div className='tex-center text-[21px] w-[241px] h-[58px] flex mx-auto mt-[37px] items-center justify-center bg-white rounded-[35px] EuclidCircular'>Schedule a call<img src={bluearrow} className='ml-[12px] w-[11px] h-[11px]' /></div></Link>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};

export default Aboutus;