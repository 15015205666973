import React from 'react'
import Navbar from './navbar'
import GalleryNavbar from './galleryNavbar'
import modular1 from '../assets/modular1.svg';
import modular2 from '../assets/modular2.svg';
import modular3 from '../assets/modular3.svg';
import modular4 from '../assets/modular4.svg';
import modular5 from '../assets/modular5.svg';
import modular6 from '../assets/modular6.svg';
import modular7 from '../assets/modular7.svg';
import modular8 from '../assets/modular8.svg';
import modular9 from '../assets/modular9.svg';
import modular10 from '../assets/modular10.svg';
import modular11 from '../assets/modular11.svg';
import modular12 from '../assets/modular12.svg';

import Footer from './footer';

export const Modularkitchen = () => {
    return (
        <div>
            <div>
                <GalleryNavbar />
            </div>
            <div className='text-[80px] Gilroy-Medium text-[#0C0C0C] ml-[134px] mt-[84px]'>Modular Kitchen</div>
            <div className='w-[124px] h-[2px] bg-black  ml-[574px]'></div>
            <div className='boxes mt-[74px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={modular1} />
                    <img src={modular2} />
                    <img src={modular3} />
                    <img src={modular4} />
                </div>
            </div>

            <div className='boxes mt-[74px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={modular5} />
                    <img src={modular6} />
                    <img src={modular7} />
                    <img src={modular8} />
                </div>
            </div>


            <div className='boxes mt-[74px] pb-[77px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={modular9} />
                    <img src={modular10} />
                    <img src={modular11} />
                    <img src={modular12} />
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}
