


import React from 'react'
import Navbar from './navbar'
import GalleryNavbar from './galleryNavbar'
import poojaunit1 from '../assets/pooja-unit1.jpg';
import poojaunit2 from '../assets/pooja-unit2.jpg';
import poojaunit3 from '../assets/pooja-unit3.jpg';
import poojaunit4 from '../assets/pooja-unit4.jpg';
import poojaunit5 from '../assets/pooja-unit5.jpg';
import poojaunit6 from '../assets/pooja-unit6.jpg';
import poojaunit7 from '../assets/pooja-unit7.jpg';
import poojaunit8 from '../assets/pooja-unit8.jpg';
import poojaunit9 from '../assets/pooja-unit9.jpg';
import poojaunit10 from '../assets/pooja-unit10.jpg';
import poojaunit11 from '../assets/pooja-unit11.jpg';
import poojaunit12 from '../assets/pooja-unit12.jpg';
import Footer from './footer';

export const PoojaUnit = () => {
    return (
        <div>
            <div>
                <GalleryNavbar />
            </div>
            <div className='text-[80px] Gilroy-Medium text-[#0C0C0C] ml-[134px] mt-[84px]'>Pooja Unit</div>
            <div className='w-[124px] h-[2px] bg-black  ml-[244px]'></div>
            <div className='boxes mt-[74px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={poojaunit1} />
                    <img src={poojaunit2} />
                    <img src={poojaunit3} />
                    <img src={poojaunit4} />
                </div>
            </div>

            <div className='boxes mt-[74px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={poojaunit5} />
                    <img src={poojaunit6} />
                    <img src={poojaunit7} />
                    <img src={poojaunit8} />
                </div>
            </div>


            <div className='boxes mt-[74px] pb-[77px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={poojaunit9} />
                    <img src={poojaunit10} />
                    <img src={poojaunit11} />
                    <img src={poojaunit12} />
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}
