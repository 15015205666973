import React from 'react';
import Slider from 'react-slick';
import gardientleft from '../assets/bluetik_mobile/Line_mb1.svg';
import gardientright from '../assets/bluetik_mobile/Lineright_mb.svg';
import testi from '../assets/bluetik_mobile/testi1.svg';

// Import CSS for slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimonials = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <button className='slick-prev'>Previous</button>,
        nextArrow: <button className='slick-next'>Next</button>,
    };

    return (
        <div>
            <div className='flex flex-row mt-[127px]  items-center justify-center mx-auto'>
                <div><img src={gardientleft} className='mr-[12px]' alt="Left gradient" /></div>
                <div className='text-black text-center AltoneTrial-Regular'>TESTIMONIALS</div>

                <div><img src={gardientright} className='ml-[12px]' alt="Right gradient" /></div>
            </div>
            <div className='text-[29px] Gilroy-Medium ml-[29px] mt-[19px] leading-[29px] '>Our <span className='linear-client'>Clinet’s </span> are <br></br>Saying.</div>
            <div className='ml-[34px] mt-[12px]'>Nunc vulputate libero et velit interdum,<br></br> ac aliquet odio mattis.</div>
            <div className='testbx ml-[21px]'>
                <Slider {...settings}>
                    <div><img src={testi} alt="Testimonial 1" /></div>
                    <div><img src={testi} alt="Testimonial 2" /></div>
                    <div><img src={testi} alt="Testimonial 3" /></div>
                </Slider>
            </div>
        </div>
    );
};

export default Testimonials;