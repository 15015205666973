import React from 'react'
import BluetikInterior from '../assets/bluetik_mobile/BluetikInteriors-logo.svg';
import facebook from '../assets/fb.svg'
import insta from '../assets/insta.svg'
import whastapp from '../assets/whts.svg'
import { Link } from 'react-router-dom';
export const FooterMobile = () => {
    return (
        <div className='ftr bg-[#0C0C0C] w-full h-[544px] mt-[121px]'>
            <div className='flex flex-row items-center justify-center mx-auto gap-9 pt-[71px]'>
                <Link to="/landmb" ><div className='text-[17px] text-white Gilroy-Regular'>HOME</div></Link>
                <Link to="/aboutusmb" ><div className='text-[17px] text-white Gilroy-Regular'>ABOUT US</div></Link>
                <Link to="/gallerymb" > <div className='text-[17px] text-white Gilroy-Regular'>GALLERY</div></Link>
            </div>
            <div className='flex flex-row items-center justify-center mx-auto gap-9 mt-[27px]'>
                <Link to="/servicemb" ><div className='text-[17px] text-white Gilroy-Regular'>SERVICE</div></Link>
                <Link to="/contactmb" ><div className='text-[17px] text-white Gilroy-Regular'>CONTACT US</div></Link>
            </div>
            <div className='Gilroy-Regular text-[15px] text-[#666666] flex items-center justify-center mx-auto mt-[27px]'>ADDRESS</div>
            <div className='Gilroy-Regular text-[15px] text-white flex items-center justify-center text-center mx-auto mt-[4px]'>
                Muthanallur Cross, Bangalore,<br /> Karnataka - 562125
            </div>

            <div className='Gilroy-Regular text-[15px] text-[#666666] flex items-center justify-center mx-auto mt-[27px]'>CONTACT</div>
            <div className='Gilroy-Regular text-[15px] text-white flex items-center justify-center text-center mx-auto mt-[4px]'>
                Bluetikinteriors@gmail.com<br />+91 9600731418
            </div>

            <div>
                <img src={BluetikInterior} className='flex items-center justify-center mx-auto mt-[55px]' />
                <div className='Gilroy-Regular text-[15px] text-[#666666] flex items-center justify-center mx-auto mt-[7px]'>The Complete Interior Solutions</div>
            </div>
            <div className='flex flex-row scilamedia gap-4 justify-center mt-[15px]'>
                <img src={facebook} className='w-[23px] h-[22px]' />
                <img src={insta} className='w-[23px] h-[22px]' />
                <img src={whastapp} className='w-[23px] h-[22px]' />
            </div>
            <div className='w-full h-[81px] bg-[#9D9D9D] mt-[24px]'>
                <div className='Gilroy-Regular text-[12px] text-[#000000] flex items-center justify-center mx-auto pt-[17px]'>2024 Bluetik Interiors. All Rights Reserved. Site by SIRA</div>
                <div className='Gilroy-Regular text-[12px] text-[#000000] flex items-center justify-center mx-auto pt-[17px]'>Privacy Policy <span className='ml-[24px]'>Terms and Condition</span></div>
            </div>
        </div>
    )
}