import React from 'react'
import NavbarMobile from './navbarMobile'
import BedmobileView1 from '../assets/BedmobileView1.jpg';
import BedmobileView2 from '../assets/BedmobileView2.jpg';
import BedmobileView3 from '../assets/BedmobileView3.jpg';
import BedmobileView4 from '../assets/BedmobileView4.jpg';
import BedmobileView5 from '../assets/BedmobileView5.jpg';
import BedmobileViewsix from '../assets/BedmobileViewsix.jpg';
import BedmobileView7 from '../assets/BedmobileView7.jpg';
import BedmobileView8 from '../assets/BedmobileView8.jpg';
// import BedmobileView9 from '../assets/BedmobileView9.jpg';
// import BedmobileView10 from '../assets/BedmobileView10.jpg';
// import BedmobileView11 from '../assets/BedmobileView11.jpg';
// import BedmobileView12 from '../assets/BedmobileView12.jpg';

// import balckarrow from '../assets/bluetik_mobile/blackarrow.svg';

function BedmobileView() {
    return (
        <div>
            <div>
                <NavbarMobile />
            </div>
            <div className='w-full h-[198px] '>
                <div className='box1'>
                    <div className='Gilroy-Medium text-[27px] text-[#0C0C0C] mt-[101px] ml-[21px]'>Bedroom</div>
                    <div className='flex flex-row'>
                        <div className='flex items-center justify-center gap-4 ml-[21px] '>
                            <div className='w-[74px] h-[2px] ml-[52px]  bg-black'></div>
                            {/* <div className='w-[89px] h-[34px] bg-white border-black border-2 rounded-[21px] text-[12px] flex items-center justify-center Gilroy-Medium'>View all<span><img src={balckarrow} className='ml-[4px]' /></span></div> */}
                        </div>
                    </div>
                    <div className='gallery'>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                            <img src={BedmobileView1} />
                            <img src={BedmobileView2} />
                        </div>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                            <img src={BedmobileView3} />
                            <img src={BedmobileView4} />
                        </div>
                    </div>

                    <div className='gallery'>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                            <img src={BedmobileView5} />
                            <img src={BedmobileViewsix} />
                        </div>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                            <img src={BedmobileView7} />
                            <img src={BedmobileView8} />
                        </div>
                    </div>


                    {/* <div className='gallery'>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                            <img src={BedmobileView9} />
                            <img src={BedmobileView10} />
                        </div>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                            <img src={BedmobileView11} />
                            <img src={BedmobileView12} />
                        </div>
                    </div> */}



                </div>
            </div>
        </div>
    )
}

export default BedmobileView