import React from 'react';
import sitelogo from '../assets/LOGO.svg';
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav className="bg-transparent p-4 flex items-center justify-between fixed top-0 left-0 right-0 z-50">
            <div className="flex items-center ml-[44px] ">
                <img src={sitelogo} alt="Site Logo" />
            </div>
            <div className="flex space-x-4 effect">
                <Link to="/" className="text-white text-[21px] hover:text-blue-500 px-5 py-9 rounded-md text-sm font-medium">HOME</Link>
                <Link to="/aboutus" className="text-white text-[21px] hover:text-blue-500 px-5 py-9 rounded-md text-sm font-medium">ABOUT US</Link>
                <Link to="/gallery" className="text-white text-[21px] hover:text-blue-500 px-5 py-9 rounded-md text-sm font-medium">GALLERY</Link>
                <Link to="/service" className="text-white text-[21px] hover:text-blue-500 px-5 py-9 rounded-md text-sm font-medium">SERVICE</Link>
                <Link to="/contactus" className="text-white text-[21px] hover:text-blue-500 px-5 py-9 rounded-md text-sm font-medium">CONTACT US</Link>
            </div>
        </nav>
    );
}

export default Navbar;