import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Slider from 'react-slick';
import gallerybg1 from '../assets/bluetik_mobile/gallerybgmb.png';
import singlebhk from '../assets/bluetik_mobile/singlebhk.svg';
import doubleebhk from '../assets/bluetik_mobile/doublebhk.svg';
import villa from '../assets/bluetik_mobile/villamb.png';
import balckarrow from '../assets/bluetik_mobile/blackarrow.svg';
import wardrobe from '../assets/bluetik_mobile/wRDROBEMB.svg';
import wardropemobile1 from '../assets/wardropemobile1.jpg';
import wardropemobile2 from '../assets/wardropemobile2.jpg';
import wardropemobile3 from '../assets/wardropemobile3.jpg';
import wardropemobile4 from '../assets/wardropemobile4.jpg';
import modualarview1 from '../assets/modualarview1.jpg';
import modualarview2 from '../assets/modualarview2.jpg';
import modualarview3 from '../assets/modualarview3.jpg';
import modualarview4 from '../assets/modualarview4.jpg';
import bedroom from '../assets/bluetik_mobile/bed.png';
import livingrooms from '../assets/bluetik_mobile/livingrooms.png';
import tvview1 from '../assets/tvview1.jpg';
import tvview2 from '../assets/tvview2.jpg';
import tvview3 from '../assets/tvview3.jpg';
import tvview4 from '../assets/tvview4.jpg';
import PoojaView1 from '../assets/PoojaView1.jpg';
import PoojaView2 from '../assets/PoojaView2.jpg';
import PoojaView3 from '../assets/PoojaViewthree.jpg';
import PoojaView4 from '../assets/PoojaView4.jpg';
import livingmbileview1 from '../assets/livingmbileview1.jpg';
import livingmbileview2 from '../assets/livingmbileview2.jpg';
import livingmbileview3 from '../assets/livingmbileview3.jpg';
import livingmbileview4 from '../assets/livingmbileview4.jpg';
import BedmobileView1 from '../assets/BedmobileView1.jpg';
import BedmobileView2 from '../assets/BedmobileView2.jpg';
import BedmobileView3 from '../assets/BedmobileView3.jpg';
import BedmobileView4 from '../assets/BedmobileView4.jpg';
import { FooterMobile } from './footerMobile';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavbarMobile from './navbarMobile';
import whatsappIcon from '../assets/bluetik_mobile/whatsapp-icon.svg';  // Import WhatsApp icon image
import instaIcon from '../assets/insta_icon.svg';
import emailIcon from '../assets/email_icon.svg';
const images = {
  wardrobe: [wardropemobile1, wardropemobile2, wardropemobile3, wardropemobile4],
  modul_kitechen: [modualarview1, modualarview2, modualarview3, modualarview4],
  bedroom: [BedmobileView1, BedmobileView2, BedmobileView3, BedmobileView4],
  livingrooms: [livingmbileview1, livingmbileview2, livingmbileview3, livingmbileview4],
  tvunits: [tvview1, tvview2, tvview3, tvview4],
  PoojaUnit: [PoojaView1, PoojaView2, PoojaView3, PoojaView4],
};

export default function GalleryMobile() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (imageArray) => {
    setModalImages(imageArray);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalImages([]);
    setCurrentIndex(0);
  };

  const gallerybg = {
    backgroundImage: `url(${gallerybg1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentIndex,
    afterChange: (current) => setCurrentIndex(current),
    centerMode: false, // Disable centerMode

  };

  return (
    <div>
      <div className='w-full h-screen mobile-only' style={gallerybg}>
        <div>
          <NavbarMobile />
        </div>
        <div className='flex flex-col items-center justify-end h-full mt-[-142px]'>
          <div className='text-[42px] font-medium text-[#FDFDFD] ml-[72px]'>
            A Showcase of
          </div>
          <div className='text-[24px] font-medium text-[#BFE1EA] mt-[-7px] ml-[158px] Gilroy-Medium '>
            Bluetik<span className='Gilroy-Regular ml-[4px]'>Gallery</span>
          </div>
        </div>
      </div>
      <div className='choice'>
        <div className='Gilroy-Regular text-[28px] flex text-center mx-auto items-center justify-center m-[24px]'>THE CHOICE</div>
        <div className='w-[105px] h-[2px] bg-[#757575] flex items-center justify-center mx-auto mt-[-14px]'></div>
        <div className='flex flex-col items-center justify-center gap-8 m-[34px]' >
          <img src={singlebhk} />
          <img src={doubleebhk} />
          <img src={villa} />
        </div>


        {/* New WhatsApp Chat Button */}
        <div style={{ position: 'fixed', bottom: '310px', leftt: '20px', marginLeft: '47px', marginTop: '-147px' }}>
          <div className='flex items-center p-2 rounded-full shadow-lg' style={{
            background: 'rgba(0, 0, 0, 0.19)',
            backdropFilter: 'blur(1.808px)',
            webkitBackdropFilter: 'blur(10px)',
            borderRadius: '42px',
            padding: '10px'
          }}>
            <a href="https://wa.me/7619691418 " target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
              <img src={whatsappIcon} alt="WhatsApp" className='w-6 h-6' />
            </a>
            <a href="https://www.instagram.com/blue_tik_interiors/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
              <img src={instaIcon} alt="Instagram" className='w-6 h-6' />
            </a>
            <a href="mailto:bluetikinteriors@gmail.com" target="_blank" rel="noopener noreferrer" className='flex items-center'>
              <img src={emailIcon} alt="Email" className='w-6 h-6' />
            </a>
          </div>
        </div>




        <div className='box1'>
          <div className='Gilroy-Medium text-[27px] text-[#0C0C0C] mt-[101px] ml-[21px]'>Wardrobe</div>
          <div className='flex flex-row'>
            <div className='flex items-center justify-center gap-4 ml-[21px] '>
              <div className='w-[234px] h-[2px] bg-black'></div>
              <Link to="/wardropeview"><div className='w-[89px] h-[34px] bg-white border-black border-2 rounded-[21px] text-[12px] flex items-center justify-center Gilroy-Medium'>View all<span><img src={balckarrow} className='ml-[4px]' /></span></div></Link>
            </div>
          </div>
          <div className='gallery'>
            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
              <img src={wardropemobile1} onClick={() => openModal(images.wardrobe)} />
              <img src={wardropemobile2} onClick={() => openModal(images.wardrobe)} />
            </div>
            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
              <img src={wardropemobile3} onClick={() => openModal(images.wardrobe)} />
              <img src={wardropemobile4} onClick={() => openModal(images.wardrobe)} />
            </div>
          </div>

        </div>

        <div className='box1'>
          <div className='Gilroy-Medium text-[27px] text-[#0C0C0C] mt-[101px] ml-[21px]'>Modular Kitchen</div>
          <div className='flex flex-row'>
            <div className='flex items-center justify-center gap-4 ml-[21px] '>
              <div className='w-[234px] h-[2px] bg-black'></div>
              <Link to="/modularview"><div className='w-[89px] h-[34px] bg-white border-black border-2 rounded-[21px] text-[12px] flex items-center justify-center Gilroy-Medium'>View all<span><img src={balckarrow} className='ml-[4px]' /></span></div></Link>
            </div>
          </div>
          <div className='gallery'>
            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
              <img src={modualarview1} onClick={() => openModal(images.modul_kitechen)} />
              <img src={modualarview2} onClick={() => openModal(images.modul_kitechen)} />
            </div>
            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
              <img src={modualarview3} onClick={() => openModal(images.modul_kitechen)} />
              <img src={modualarview4} onClick={() => openModal(images.modul_kitechen)} />
            </div>
          </div>

        </div>

        <div className='box1'>
          <div className='Gilroy-Medium text-[27px] text-[#0C0C0C] mt-[101px] ml-[21px]'>Bedroom</div>
          <div className='flex flex-row'>
            <div className='flex items-center justify-center gap-4 ml-[21px] '>
              <div className='w-[234px] h-[2px] bg-black'></div>
              <Link to="/bedmbileview"> <div className='w-[89px] h-[34px] bg-white border-black border-2 rounded-[21px] text-[12px] flex items-center justify-center Gilroy-Medium'>View all<span><img src={balckarrow} className='ml-[4px]' /></span></div></Link>
            </div>
          </div>
          <div className='gallery'>
            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
              <img src={BedmobileView1} onClick={() => openModal(images.bedroom)} />
              <img src={BedmobileView2} onClick={() => openModal(images.bedroom)} />
            </div>
            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
              <img src={BedmobileView3} onClick={() => openModal(images.bedroom)} />
              <img src={BedmobileView4} onClick={() => openModal(images.bedroom)} />
            </div>
          </div>

        </div>

        <div className='box1'>
          <div className='Gilroy-Medium text-[27px] text-[#0C0C0C] mt-[101px] ml-[21px]'>Living Room</div>
          <div className='flex items-center justify-center gap-4 ml-[7px] '>
            <div className='w-[234px] h-[2px] bg-black'></div>
            <Link to="/livingmbileview"> <div className='w-[89px] h-[34px] bg-white border-black border-2 rounded-[21px] text-[12px] flex items-center justify-center Gilroy-Medium'>View all<span><img src={balckarrow} className='ml-[4px]' /></span></div></Link>
          </div>
          <div className='gallery'>
            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
              <img src={livingmbileview1} onClick={() => openModal(images.livingrooms)} />
              <img src={livingmbileview2} onClick={() => openModal(images.livingrooms)} />
            </div>
            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
              <img src={livingmbileview3} onClick={() => openModal(images.livingrooms)} />
              <img src={livingmbileview4} onClick={() => openModal(images.livingrooms)} />
            </div>
          </div>
          {/* <div className='flex items-center justify-center mt-4'>
            <div className='w-[89px] h-[34px] bg-white border-black border-2 rounded-[12px] flex items-center justify-center'>View all<span><img src={balckarrow} className='ml-[4px]' /></span></div>
          </div> */}
        </div>

        <div className='box1'>
          <div className='Gilroy-Medium text-[27px] text-[#0C0C0C] mt-[101px] ml-[21px]'>TV Units</div>
          <div className='flex flex-row'>
            <div className='flex items-center justify-center gap-4 ml-[21px] '>
              <div className='w-[234px] h-[2px] bg-black'></div>
              <Link to="/tvunitview"><div className='w-[89px] h-[34px] bg-white border-black border-2 rounded-[21px] text-[12px] flex items-center justify-center Gilroy-Medium'>View all<span><img src={balckarrow} className='ml-[4px]' /></span></div></Link>
            </div>
          </div>
          <div className='gallery'>
            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
              <img src={tvview1} onClick={() => openModal(images.tvunits)} />
              <img src={tvview2} onClick={() => openModal(images.tvunits)} />
            </div>
            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
              <img src={tvview3} onClick={() => openModal(images.tvunits)} />
              <img src={tvview4} onClick={() => openModal(images.tvunits)} />
            </div>
          </div>

        </div>

        <div className='box1'>
          <div className='Gilroy-Medium text-[27px] text-[#0C0C0C] mt-[101px] ml-[21px]'>Pooja Unit</div>
          <div className='flex flex-row'>
            <div className='flex items-center justify-center gap-4 ml-[21px] '>
              <div className='w-[234px] h-[2px] bg-black'></div>
              <Link to="/poojaview"><div className='w-[89px] h-[34px] bg-white border-black border-2 rounded-[21px] text-[12px] flex items-center justify-center Gilroy-Medium'>View all<span><img src={balckarrow} className='ml-[4px]' /></span></div></Link>
            </div>
          </div>
          <div className='gallery'>
            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
              <img src={PoojaView1} onClick={() => openModal(images.PoojaUnit)} />
              <img src={PoojaView2} onClick={() => openModal(images.PoojaUnit)} />
            </div>
            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
              <img src={PoojaView3} onClick={() => openModal(images.PoojaUnit)} />
              <img src={PoojaView4} onClick={() => openModal(images.PoojaUnit)} />
            </div>
          </div>

        </div>
      </div>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={{ content: { width: '391px', height: '355px', marginTop: '281px', borderRadius: '10px', position: 'unset' } }}>
        <button onClick={closeModal} style={{ position: 'absolute', top: '-20px', right: '-20px', background: 'white', border: 'none', borderRadius: '50%', padding: '5px' }}>X</button>
        <Slider {...sliderSettings}>
          {modalImages.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
          ))}
        </Slider>
      </Modal>

      <FooterMobile />
    </div>
  );
}







