import React from 'react'
import Navbar from './navbar'
import GalleryNavbar from './galleryNavbar'
import wardobe1 from '../assets/wardobe1.svg';
import wardobe2 from '../assets/wardobe2.svg';
import wardobe3 from '../assets/wardobe3.svg';
import wardobe4 from '../assets/wardobe4.svg';
import wardobe5 from '../assets/wardobe5.svg';
import wardobe6 from '../assets/wardobe6.svg';
import wardobe7 from '../assets/wardobe7.svg';
import wardobe8 from '../assets/wardobe8.svg';
import wardobe9 from '../assets/wardobe9.svg';
import wardobe10 from '../assets/wardobe10.svg';
import wardobe11 from '../assets/wardobe11.svg';
import wardobe12 from '../assets/wardobe12.svg';

import Footer from './footer';

export const Wardrope = () => {
    return (
        <div>
            <div>
                <GalleryNavbar />
            </div>
            <div className='text-[80px] Gilroy-Medium text-[#0C0C0C] ml-[134px] mt-[84px]'>Wardrobe</div>
            <div className='w-[124px] h-[2px] bg-black  ml-[374px]'></div>
            <div className='boxes mt-[74px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={wardobe1} />
                    <img src={wardobe2} />
                    <img src={wardobe3} />
                    <img src={wardobe4} />
                </div>
            </div>

            <div className='boxes mt-[74px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={wardobe5} />
                    <img src={wardobe6} />
                    <img src={wardobe7} />
                    <img src={wardobe8} />
                </div>
            </div>


            <div className='boxes mt-[74px] pb-[77px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={wardobe9} />
                    <img src={wardobe10} />
                    <img src={wardobe11} />
                    <img src={wardobe12} />
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}
