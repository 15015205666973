import React from 'react'
import NavbarMobile from './navbarMobile'
import livingmbileview1 from '../assets/livingmbileview1.jpg';
import livingmbileview2 from '../assets/livingmbileview2.jpg';
import livingmbileview3 from '../assets/livingmbileview3.jpg';
import livingmbileview4 from '../assets/livingmbileview4.jpg';
import livingmbileview5 from '../assets/livingmbileview5.jpg';
import livingmbileviewsix from '../assets/livingmbileviewsix.jpg';
import livingmbileview7 from '../assets/livingmbileview7.jpg';
import livingmbileview8 from '../assets/livingmbileview8.jpg';
import livingmbileview9 from '../assets/livingmbileview9.jpg';
import livingmbileview10 from '../assets/livingmbileview10.jpg';
import livingmbileview11 from '../assets/livingmbileview11.jpg';
import livingmbileview12 from '../assets/livingmbileview12.jpg';

// import balckarrow from '../assets/bluetik_mobile/blackarrow.svg';

function LivingmobileView() {
    return (
        <div>
            <div>
                <NavbarMobile />
            </div>
            <div className='w-full h-[198px] '>
                <div className='box1'>
                    <div className='Gilroy-Medium text-[27px] text-[#0C0C0C] mt-[101px] ml-[21px]'>Living</div>
                    <div className='flex flex-row'>
                        <div className='flex items-center justify-center gap-4 ml-[21px] '>
                            <div className='w-[74px] h-[2px] ml-[52px]  bg-black'></div>
                            {/* <div className='w-[89px] h-[34px] bg-white border-black border-2 rounded-[21px] text-[12px] flex items-center justify-center Gilroy-Medium'>View all<span><img src={balckarrow} className='ml-[4px]' /></span></div> */}
                        </div>
                    </div>
                    <div className='gallery'>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                            <img src={livingmbileview1} />
                            <img src={livingmbileview2} />
                        </div>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                            <img src={livingmbileview3} />
                            <img src={livingmbileview4} />
                        </div>
                    </div>

                    <div className='gallery'>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                            <img src={livingmbileview5} />
                            <img src={livingmbileviewsix} />
                        </div>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                            <img src={livingmbileview7} />
                            <img src={livingmbileview8} />
                        </div>
                    </div>


                    <div className='gallery'>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                            <img src={livingmbileview9} />
                            <img src={livingmbileview10} />
                        </div>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                            <img src={livingmbileview11} />
                            <img src={livingmbileview12} />
                        </div>
                    </div>



                </div>
            </div>
        </div>
    )
}

export default LivingmobileView