import React from 'react'
import NavbarMobile from './navbarMobile'
import LivingView1 from '../assets/LivingView1.jpg';
import LivingView2 from '../assets/LivingView2.jpg';
import LivingView3 from '../assets/LivingView3.jpg';
import LivingView4 from '../assets/LivingView4.jpg';
import LivingView5 from '../assets/LivingView5.jpg';
import LivingViewsix from '../assets/LivingViewsix.jpg';
import LivingView7 from '../assets/LivingView7.jpg';
import LivingView8 from '../assets/LivingView8.jpg';
import LivingView9 from '../assets/LivingView9.jpg';
import LivingView10 from '../assets/LivingView10.jpg';
import LivingView11 from '../assets/LivingView11.jpg';
import LivingView12 from '../assets/LivingView12.jpg';

// import balckarrow from '../assets/bluetik_mobile/blackarrow.svg';

function LivingView() {
    return (
        <div>
            <div>
                <NavbarMobile />
            </div>
            <div className='w-full h-[198px] '>
                <div className='box1'>
                    <div className='Gilroy-Medium text-[27px] text-[#0C0C0C] mt-[101px] ml-[21px]'>Wardrobe</div>
                    <div className='flex flex-row'>
                        <div className='flex items-center justify-center gap-4 ml-[21px] '>
                            <div className='w-[74px] h-[2px] ml-[52px]  bg-black'></div>
                            {/* <div className='w-[89px] h-[34px] bg-white border-black border-2 rounded-[21px] text-[12px] flex items-center justify-center Gilroy-Medium'>View all<span><img src={balckarrow} className='ml-[4px]' /></span></div> */}
                        </div>
                    </div>
                    <div className='gallery'>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                            <img src={LivingView1} />
                            <img src={LivingView2} />
                        </div>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                            <img src={LivingView3} />
                            <img src={LivingView4} />
                        </div>
                    </div>

                    <div className='gallery'>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                            <img src={LivingView5} />
                            <img src={LivingViewsix} />
                        </div>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                            <img src={LivingView7} />
                            <img src={LivingView8} />
                        </div>
                    </div>


                    <div className='gallery'>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                            <img src={LivingView9} />
                            <img src={LivingView10} />
                        </div>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                            <img src={LivingView11} />
                            <img src={LivingView12} />
                        </div>
                    </div>



                </div>
            </div>
        </div>
    )
}

export default LivingView