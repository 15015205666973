
import React from 'react'
import Navbar from './navbar'
import GalleryNavbar from './galleryNavbar'
import wardobe1 from '../assets/wardrobe.jpg';
import modula1 from '../assets/modulakitchen.jpg';
import bedroom1 from '../assets/bedrooms.png';
import living1 from '../assets/livings.png';
import Footer from './footer';

export const BedRoom = () => {
    return (
        <div>
            <div>
                <GalleryNavbar />
            </div>
            <div className='text-[80px] Gilroy-Medium text-[#0C0C0C] ml-[134px] mt-[84px]'>BedRoom</div>
            <div className='w-[124px] h-[2px] bg-black  ml-[374px]'></div>
            <div className='boxes mt-[74px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={wardobe1} />
                    <img src={modula1} />
                    <img src={bedroom1} />
                    <img src={living1} />
                </div>
            </div>

            <div className='boxes mt-[74px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={modula1} />
                    <img src={living1} />
                    <img src={bedroom1} />
                    <img src={wardobe1} />
                </div>
            </div>


            <div className='boxes mt-[74px] pb-[77px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={wardobe1} />
                    <img src={modula1} />
                    <img src={bedroom1} />
                    <img src={living1} />
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}
