
import { useEffect } from 'react';
import Aboutus from './components/aboutus';
import Gallery from './components/gallery';
import Service from './components/service';
import { ContactUs } from './components/contactUs';
import { Landing } from './components/landing';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { LandingMobile } from './components/landingMobile';
import { AboutusMobile } from './components/aboutusMobile';
import GalleryMobile from './components/galleryMobile';
import ServiceMb from './components/serviceMb';
import { ContactusMobile } from './components/contactusMobile';
import { Wardrope } from './components/wardrope';
import WardropeMoile from './components/wardropeMoile';
import { Modularkitchen } from './components/modularKitchen';
import { BedRoom } from './components/bedRoom';
import { LivingRoom } from './components/livingRoom';
import { TvUnit } from './components/tvUnit';
import { PoojaUnit } from './components/poojUnit';
import WardropeView from './components/wardropeview';
import ModularView from './components/modulkitchenview';
import TvView from './components/tvunitView';
import PoojaView from './components/poojaUnitview';
import LivingView from './components/livingview';
import LivingmobileView from './components/livingmobileView';
import BedmobileView from './components/bedrrommobileView';
const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/service" element={<Service />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/wardrope" element={<Wardrope />} />
          <Route path="/wardropeview" element={<WardropeView />} />
          <Route path="/modularkitchen" element={<Modularkitchen />} />
          <Route path="/modularview" element={<ModularView />} />
          <Route path="/bedroom" element={<BedRoom />} />
          <Route path="/bedmbileview" element={<BedmobileView />} />
          <Route path="/livingroom" element={<LivingRoom />} />
          <Route path="/livingview" element={<LivingView />} />
          <Route path="/livingmbileview" element={<LivingmobileView />} />
          <Route path="/tvunit" element={<TvUnit />} />
          <Route path="/tvunitview" element={<TvView />} />
          <Route path="/poojauniit" element={<PoojaUnit />} />
          <Route path="/poojaview" element={<PoojaView />} />
          <Route path="/landmb" element={<LandingMobile />} />
          <Route path="/servicemb" element={<ServiceMb />} />
          <Route path="/aboutusmb" element={<AboutusMobile />} />
          <Route path="/contactmb" element={<ContactusMobile />} />
          <Route path="/gallerymb" element={<GalleryMobile />} />
          <Route path="/wardropemb" element={<WardropeMoile />} />
        </Routes>
      </div>
    </Router>
    // <div>
    //   {/* <LandingMobile /> */}
    //   {/* <AboutusMobile /> */}
    //   {/* <GalleryMobile /> */}
    //   {/* <ServiceMb /> */}
    //   {/* <ContactusMobile /> */}
    // </div>
  );
}

export default App;
