import React from 'react'
import servbg from '../assets/service_bg.png'
import resendial from '../assets/resendial.png'
import commercial from '../assets/commercial.png'
import decorative from '../assets/decorative.png'
import symp from '../assets/title_symbol.svg'
import whatsappIcon from '../assets/bluetik_mobile/whatsapp-icon.svg';  // Import WhatsApp icon image
import instaIcon from '../assets/insta_icon.svg';
import emailIcon from '../assets/email_icon.svg';
import Navbar from './navbar'
import Footer from './footer'
const Service = () => {
    const servicebg = {
        backgroundImage: `url(${servbg})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    return (
        <div>
            <div className='service bg-[#0C0C0C] h-[3700px] overflow-x-hidden hide-on-mobile'>
                <div>
                    <Navbar />
                </div>
                <div className='flex mx-auto items-center justify-center mt-[181px]'>
                    <div className='w-[1800px] h-[613px] ' style={servicebg}>
                        <div className='text-[81px] flex items-center justify-center mx-auto text-center servlinear pt-[59px] EuclidCircular linear-service'>Service Craft</div>
                        <div className='w-[1196px] text-[32px] text-center mx-auto leading-[39px] flex items-center justify-center text-[#BFBFBF] Gilroy-Regular'>Elevating spaces with impeccable attention to detail, crafting extraordinary experiences. Beyond the picture, we transcend imagination, setting new standards in design.</div>
                    </div>
                </div>
                <div style={{ position: 'fixed', bottom: '80px', leftt: '20px', marginLeft: '47px', marginTop: '147px' }}>
                    <div className='flex items-center p-2 rounded-full shadow-lg' style={{
                        background: 'rgba(0, 0, 0, 0.19)',
                        backdropFilter: 'blur(1.808px)',
                        webkitBackdropFilter: 'blur(10px)',
                        borderRadius: '42px',
                        padding: '10px'
                    }}>
                        <a href="https://wa.me/7619691418" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                            <img src={whatsappIcon} alt="WhatsApp" className='w-6 h-6' />
                        </a>
                        <a href="https://www.instagram.com/blue_tik_interiors/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                            <img src={instaIcon} alt="Instagram" className='w-6 h-6' />
                        </a>
                        <a href="mailto:bluetikinteriors@gmail.com" target="_blank" rel="noopener noreferrer" className='flex items-center'>
                            <img src={emailIcon} alt="Email" className='w-6 h-6' />
                        </a>
                    </div>
                </div>
                <div className='flex mx-auto items-center justify-center mt-[52px]'>
                    <img src={resendial} />
                </div>
                <div className='list flex flex-row mx-auto items-center justify-center gap-5 mt-[94px] ml-[128px]'>
                    <div className='flex flex-col w-[23%] h-[248px]'>
                        <div className='flex flex-row'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px] Gilroy-Medium'>Modular kitchen</div>
                        </div>
                        <div className='flex flex-row mt-[18px]'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px] Gilroy-Medium'>Wardrobes</div>
                        </div>
                        <div className='flex flex-row mt-[18px]'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px] Gilroy-Medium'>TV Unit</div>
                        </div>
                        <div className='flex flex-row mt-[18px]'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px] Gilroy-Medium'>Pooja Unit</div>
                        </div>
                    </div>
                    <div className='flex flex-col w-[23%] h-[248px]'>
                        <div className='flex flex-row'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px] Gilroy-Medium'>Living Room</div>
                        </div>
                        <div className='flex flex-row mt-[18px]'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px] Gilroy-Medium'>Dining Room</div>
                        </div>
                        <div className='flex flex-row mt-[18px]'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px] Gilroy-Medium'>Bed Room</div>
                        </div>
                        <div className='flex flex-row mt-[18px]'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px] Gilroy-Medium'>False Ceiling</div>
                        </div>
                    </div>
                    <div className='flex flex-col w-[21%] h-[248px]'>
                        <div className='flex flex-row'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px] Gilroy-Medium'>Crockery Unit</div>
                        </div>
                        <div className='flex flex-row mt-[18px]'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px] Gilroy-Medium'>Apartment </div>
                        </div>
                        <div className='flex flex-row mt-[18px]'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px] Gilroy-Medium'>Villa Interiors</div>
                        </div>
                        <div className='flex flex-row mt-[18px]'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px] Gilroy-Medium'>Storage room</div>
                        </div>
                    </div>
                </div>

                <div className='flex mx-auto items-center justify-center mt-[172px]'>
                    <img src={commercial} />
                </div>


                <div className='list flex flex-row mx-auto items-center justify-center gap-5 mt-[94px] ml-[128px]'>
                    <div className='flex flex-col w-[23%] h-[248px]'>
                        <div className='flex flex-row'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px]'>Showroom</div>
                        </div>
                        <div className='flex flex-row mt-[18px]'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px]'>Retail Showroom</div>
                        </div>

                    </div>
                    <div className='flex flex-col w-[23%] h-[248px]'>
                        <div className='flex flex-row'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px]'>Beauty Parlor</div>
                        </div>
                        <div className='flex flex-row mt-[18px]'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px]'>Studio</div>
                        </div>

                    </div>
                    <div className='flex flex-col w-[21%] h-[248px]'>
                        <div className='flex flex-row'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px]'>Readymade Shops</div>
                        </div>
                        <div className='flex flex-row mt-[18px]'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px]'>Boutique</div>
                        </div>

                    </div>
                </div>

                <div className='flex mx-auto items-center justify-center mt-[172px]'>
                    <img src={decorative} />
                </div>
                <div className='list flex flex-row mx-auto items-center justify-center gap-5 mt-[94px] ml-[128px]'>
                    <div className='flex flex-col w-[23%] h-[248px]'>
                        <div className='flex flex-row'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px]'>Vertical Gardening</div>
                        </div>
                        <div className='flex flex-row mt-[18px]'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px]'>Furniture’s</div>
                        </div>

                    </div>
                    <div className='flex flex-col w-[23%] h-[248px]'>
                        <div className='flex flex-row'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px]'>Hand Curving</div>
                        </div>


                    </div>
                    <div className='flex flex-col w-[21%] h-[248px]'>
                        <div className='flex flex-row'>
                            <div className='text-center mt-[12px]'><img src={symp} /></div>
                            <div className='text-[32px] text-white ml-[18px]'>Wall Decors</div>
                        </div>


                    </div>
                </div>
                <div className='w-[634px] h-[2px] bg-red-50 flex items-center justify-center mx-auto mt-[149px]'></div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}

export default Service