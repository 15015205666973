import React from 'react'
import NavbarMobile from './navbarMobile'
import modualarview1 from '../assets/modualarview1.jpg';
import modualarview2 from '../assets/modualarview2.jpg';
import modualarview3 from '../assets/modualarview3.jpg';
import modualarview4 from '../assets/modualarview4.jpg';
import modualarview5 from '../assets/modualarview5.jpg';
import modualarviewsix from '../assets/modualarviewsix.jpg';
import modualarview7 from '../assets/modualarview7.jpg';
import modualarview8 from '../assets/modualarview8.jpg';
import modualarview9 from '../assets/modualarview9.jpg';
import modualarview10 from '../assets/modualarview10.jpg';
import modualarview11 from '../assets/modualarview11.jpg';
import modualarview12 from '../assets/modualarview12.jpg';
import { FooterMobile } from './footerMobile';

// import balckarrow from '../assets/bluetik_mobile/blackarrow.svg';

function ModularView() {
    return (
        <div>
            <div>
                <div>
                    <NavbarMobile />
                </div>
                <div className='w-full h-[198px] '>
                    <div className='box1'>
                        <div className='Gilroy-Medium text-[27px] text-[#0C0C0C] mt-[101px] ml-[21px]'>Modular Kitchen</div>
                        <div className='flex flex-row'>
                            <div className='flex items-center justify-center gap-4 ml-[21px] '>
                                <div className='w-[74px] h-[2px] ml-[52px]  bg-black'></div>
                                {/* <div className='w-[89px] h-[34px] bg-white border-black border-2 rounded-[21px] text-[12px] flex items-center justify-center Gilroy-Medium'>View all<span><img src={balckarrow} className='ml-[4px]' /></span></div> */}
                            </div>
                        </div>
                        <div className='gallery'>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                                <img src={modualarview1} />
                                <img src={modualarview2} />
                            </div>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                                <img src={modualarview3} />
                                <img src={modualarview4} />
                            </div>
                        </div>

                        <div className='gallery'>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                                <img src={modualarview5} />
                                <img src={modualarviewsix} />
                            </div>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                                <img src={modualarview7} />
                                <img src={modualarview8} />
                            </div>
                        </div>


                        <div className='gallery'>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                                <img src={modualarview9} />
                                <img src={modualarview10} />
                            </div>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                                <img src={modualarview11} />
                                <img src={modualarview12} />
                            </div>
                        </div>



                    </div>
                </div>
                <div className='mt-[1178px]'>
                    <FooterMobile />
                </div>
            </div>
        </div>
    )
}

export default ModularView