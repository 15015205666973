import React from 'react';
import bluetik from '../assets/Bluetik_gradient.svg';
import landingbanner from '../assets/home_banner.png';
import whitearw from '../assets/white-arrow.svg';
import arrow from '../assets/blue_arrows.svg';
import vision from '../assets/Vission.svg';
import mision from '../assets/mission.svg';
import values from '../assets/valuess.svg';
import wardrobe from '../assets/wordrope.png';
import modelkitchen from '../assets/modularkitchen.png';
import bedroom from '../assets/bedroom.png';
import living from '../assets/living.png';
import tv from '../assets/tv.png';
import pooja from '../assets/pooja.png';
import grad_line from '../assets/gradient_line.png';
import right_line from '../assets/sericerihgt-linear.svg';
import contact_line from '../assets/contact_line.svg';
import bluetikprocess from '../assets/bluetik_process.png';
import design from '../assets/design.png';
import plan from '../assets/plan.png';
import live from '../assets/live.png';
import Stats from './state';
import Testimonials from './testimonials';
import Navbar from './navbar';
import LandingContactUs from './landingContact';
import Footer from './footer';
import tesk from '../assets/bluetikbg.jpg'
import bluearrow from '../assets/blue_arrows.svg'
import { LandingMobile } from './landingMobile';
import whatsappIcon from '../assets/bluetik_mobile/whatsapp-icon.svg';  // Import WhatsApp icon image
import instaIcon from '../assets/insta_icon.svg';
import emailIcon from '../assets/email_icon.svg';
import testimonial_line1 from '../assets/testimonial-gradient1s.svg';
import testimonial_line2 from '../assets/testimonial-gradient2.svg';
import lalitha from '../assets/lalitha.svg';
import vivek from '../assets/viveks.svg';
import akilan from '../assets/akilan.svg';


import { Link } from 'react-router-dom';


export const Landing = () => {
    const bgbanner = {
        backgroundImage: `url(${landingbanner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
    };

    return (
        <div>
            <div className="w-screen overflow-x-hidden hide-on-mobile">
                <div className="h-[1529px]" style={bgbanner} >
                    <Navbar />
                    <div className='text-[70px] text-left ml-[158px] pt-[392px] EuclidCircularbold text-[#111111]'>COMFORT IN <span className='EuclidCircularMedium'>INTERIORS.</span></div>
                    <div className='text-[22px] text-[#111111] ml-[178px] Fontspring-normal'>Experience luxury and leisure with our carefully chosen interiors</div>
                    <div className='flex flex-row w-screen h-[194px] '>
                        <div className='flex flex-col w-[40%] h-[194px] '></div>
                        <div className='flex flex-col w-[60%] h-[194px]'>
                            <div className='flex flex-row mt-[698px]'>
                                <div className='flex flex-col w-[50%] h-[194px] '>
                                    <div className='text-[32px] text-white flex'>MEET A DESIGNER<span className='flex ml-[12px]'><img src={whitearw} alt="arrow" /></span></div>
                                    <div className='text-[17px] leading-[23px] text-white w-[366px] mt-[27px] Gilroy-Medium'>Step into the world of creativity with our designers - your partners in turning visions into reality.</div>
                                </div>
                                <div className='border border-l-[0.5px] mr-[148px] h-[128px] text-black'></div>
                                <div className='flex flex-col w-[50%] h-[194px] '>
                                    <Link to="/gallery"><div className='text-[32px] text-white flex'>VIEW PROJECTS<span className='flex ml-[12px]'><img src={whitearw} alt="arrow" /></span></div></Link>
                                    <div className='text-[17px] leading-[23px] text-white w-[366px] mt-[27px] Gilroy-Medium'>Commence on a journey of inspiration as you explore our portfolio of meticulously crafted spaces.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ position: 'fixed', bottom: '80px', leftt: '20px', marginLeft: '47px', marginTop: '147px' }}>
                    <div className='flex items-center p-2 rounded-full shadow-lg' style={{
                        background: 'rgba(0, 0, 0, 0.19)',
                        backdropFilter: 'blur(1.808px)',
                        webkitBackdropFilter: 'blur(10px)',
                        borderRadius: '42px',
                        padding: '10px'
                    }}>
                        <a href="https://wa.me/7619691418" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                            <img src={whatsappIcon} alt="WhatsApp" className='w-6 h-6' />
                        </a>
                        <a href="https://www.instagram.com/blue_tik_interiors/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                            <img src={instaIcon} alt="Instagram" className='w-6 h-6' />
                        </a>
                        <a href="mailto:bluetikinteriors@gmail.com" target="_blank" rel="noopener noreferrer" className='flex items-center'>
                            <img src={emailIcon} alt="Email" className='w-6 h-6' />
                        </a>
                    </div>
                </div>
                <div className='section2 h-auto w-full bg-[#0C0C0C] pb-[114px] mt-[-94px]'>
                    <img src={bluetik} className='ml-[148px] pb-[11px] pt-[159px]' alt="bluetik" />
                    <div className='text-[30px] text-[#C0C0C0] w-[1315px] ml-[148px] Gilroy-Regular'>Experience luxury and leisure with our carefully chosen interiors, which put comfort above everything else without sacrificing design. Discover environments where every nook and cranny beckons you to relax and refresh.</div>
                    <div className="flex flex-row mx-auto items-center justify-center">
                        <div className="flex flex-col w-[30%] h-auto mt-[157px] ">
                            <div className="flex">
                                <img src={vision} className="ml-[154px] w-[120px] h-[105px]" alt="vision" />
                                <div className="text-[40px] text-[#F0F0F0] ml-[48px] pt-[48px] AltoneTrial-Regular">Vision</div>
                            </div>
                            <div className="text-[18px] text-[#D9D9D9] w-[331px] ml-[154px] mt-[42px] Fontspring-reqular">Redefining living with individuality and elegance.</div>
                        </div>
                        <div className="flex flex-col w-[30%] h-auto mt-[157px] ">
                            <div className="flex">
                                <img src={mision} className="ml-[154px] w-[120px] h-[105px]" alt="mission" />
                                <div className="text-[40px] text-[#F0F0F0] ml-[48px] pt-[48px] AltoneTrial-Regular">Mission</div>
                            </div>
                            <div className="text-[18px] text-[#D9D9D9] w-[423px] ml-[154px] mt-[42px] Fontspring-reqular">Innovative designs enrich lives, inspire experiences, one space at a time.</div>
                        </div>
                        <div className="flex flex-col w-[30%] h-auto mt-[157px] ">
                            <div className="flex">
                                <img src={values} className="ml-[154px] w-[120px] h-[105px]" alt="values" />
                                <div className="text-[40px] text-[#F0F0F0] ml-[48px] pt-[48px] AltoneTrial-Regular">Values</div>
                            </div>
                            <div className="text-[18px] text-[#D9D9D9] w-[359px] ml-[154px] mt-[42px] Fontspring-reqular">Integrity, creativity, collaboration, excellence: pillars of exceptional spaces.</div>
                        </div>
                    </div>
                    <div className="flex flex-row mt-[174px]">
                        <div className="flex flex-col w-[50%] h-[144px] b"></div>
                        <div className="flex flex-col w-[50%] h-[144px] bg-g">
                            <div className="text-[22px] text-[#E2E2E2] text-right mr-[147px] Fontspring-reqular">Delivered with perfection when passion and creativity meet. Converting spaces<br /> into distinctive contemplations.</div>
                            <Link to="/aboutus"><div className="text-[22px] text-white text-center flex flex-row items-center justify-center rounded-[33px] ml-[577px] mt-[34px]  px-4 py-4 bg-[#272727] w-[212px]">ABOUT US<span><img src={arrow} className='ml-[12px]' alt="arrow" /></span></div></Link>
                        </div>
                    </div>

                </div>
                <div className='services_sec bg-[#0D0D0D]  pb-[84px]'>
                    <div className="collective  flex items-center justify-center mx-auto pt-[74px]">
                        <div><img src={grad_line} className="w-[142px] mr-[12px]" alt="gradient line" /></div>
                        <div className="text-[#EAEAEA] ">SERVICES</div>
                        <div><img src={right_line} className="w-[142px] ml-[12px]" alt="gradient line" /></div>
                    </div>
                    <div className="text-[30px] text-[#EAEAEA] text-center w-[1371px] leading-[39px] flex mx-auto mt-[47px] Gilroy-Medium">From concept to execution, our team of talented designers will work closely with you to create spaces that are both functional and aesthetically pleasing. Whether it's your home, office, or commercial space, we'll tailor our designs to suit your unique needs and preferences.</div>
                    <div className="sec3">
                        <div className="flex flex-row mx-auto items-center justify-center">
                            <div className="flex flex-col w-full h-auto ml-[52px] ">
                                <Link to="/wardrope"> <img src={wardrobe} className="w-[539px] h-[387px]" alt="wardrobe" /></Link>
                            </div>
                            <div className="flex flex-col w-full h-auto mt-[274px]">
                                <Link to="/modularkitchen"> <img src={modelkitchen} className="w-[539px] h-[387px]" alt="modular kitchen" /></Link>
                            </div>
                            <div className="flex flex-col w-full h-auto">
                                <Link to="/bedroom"><img src={bedroom} className="w-[539px] h-[387px]" alt="bedroom" /></Link>
                            </div>
                        </div>
                    </div>
                    <div className="sec4">
                        <div className="flex flex-row mx-auto items-center justify-center">
                            <div className="flex flex-col w-full h-auto ml-[52px] ">
                                <Link to="/livingroom"> <img src={living} className="w-[539px] h-[387px]" alt="living room" /></Link>
                            </div>
                            <div className="flex flex-col w-full h-auto mt-[274px]">
                                <Link to="/tvunit"><img src={tv} className="w-[539px] h-[387px]" alt="tv room" /></Link>
                            </div>
                            <div className="flex flex-col w-full h-auto">
                                <Link to="/poojauniit"><img src={pooja} className="w-[539px] h-[387px]" alt="pooja room" /></Link>
                            </div>
                        </div>
                    </div>
                    <div className="text-[37px] text-[#EAEAEA] flex items-center justify-center w-[738px] mx-auto leading-[43px] text-center mt-[174px] Gilroy-Medium">
                        We Combines comfort and Refined Designs with the most innovative Technologies
                    </div>
                    <Link to="/gallery"><div className="w-[187px] h-[58px] flex mx-auto mt-[32px]  items-center justify-center bg-[#111111] text-white rounded-[34px]">
                        VIEW ALL <span><img src={bluearrow} className='ml-[12px]' /></span>
                    </div></Link>
                </div>
                <div className="collective bg-[#0C0C0C] h-[394px] flex items-center justify-center mx-auto pt-[4px] ">
                    <div><img src={grad_line} className="w-[142px] mr-[12px]" alt="gradient line" /></div>
                    <div className="text-white AltoneTrial-Regular">COLLECTIVE TASKS</div>
                    <div><img src={right_line} className="w-[142px] ml-[12px]" alt="gradient line" /></div>
                </div>
                <div className="text-white flex items-center justify-center bg-[#0C0C0C] text-[52px] Gilroy-Regular  mt-[-84px]">Our team ensures  <span className="text-[#00A5D8] ml-[12px] mr-[12px] Gilroy-Medium">Unparalleled Excellence</span> in every Homes.</div>
                <div className=' pb-[54px]'>
                    <Stats />
                </div>

                <div className='bg-[#0C0C0C] pt-[44px] pb-[57px] mt-[-74px]'>
                    <div className='process w-[98%] rounded-[22px]  mx-auto bg-[#F1F1F3]'>
                        <div className='flex items-center justify-center w-[427px] h-[123px] mx-auto pt-[114px]'><img src={bluetikprocess} alt="bluetik process" /></div>
                        <div className='3-col flex flex-row w-[92%] mx-auto gap-8 mt-[113px]'>
                            <div className='flex flex-col w-screen h-[344px]'>
                                <img className='flex mx-auto items-center justify-center w-[102px] h-[99px]' src={design} alt="design" />
                                <div className='w-[144px] h-[2px] bg-[#B5B5B5] flex mx-auto mr-[124px] mt-[24px]'></div>
                                <div className='text-[40px] text-[#0095C3] flex mx-auto Fontspring-reqular'>Design</div>
                                <div className='text-[20px] text-[#A2A2A2] w-[420px] leading-[28px] flex mx-auto Fontspring-reqular'>Create lasting impact with our innovative designs, blending classic elegance and modern chic to awe and inspire.</div>
                            </div>
                            <div className='flex flex-col w-screen h-[344px]'>
                                <img className='flex mx-auto items-center justify-center w-[102px] h-[99px]' src={plan} alt="plan" />
                                <div className='w-[144px] h-[2px] bg-[#B5B5B5] flex mx-auto mr-[124px] mt-[24px]'></div>
                                <div className='text-[40px] text-[#0095C3] flex mx-auto Fontspring-reqular'>Plan</div>
                                <div className='text-[20px] text-[#A2A2A2] w-[352px] leading-[28px] flex mx-auto Fontspring-reqular'>Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</div>
                            </div>
                            <div className='flex flex-col w-screen h-[344px]'>
                                <img className='flex mx-auto items-center justify-center w-[102px] h-[99px]' src={live} alt="live" />
                                <div className='w-[144px] h-[2px] bg-[#B5B5B5] flex mx-auto mr-[124px] mt-[24px]'></div>
                                <div className='text-[40px] text-[#0095C3] flex mx-auto Fontspring-reqular'>Live</div>
                                <div className='text-[20px] text-[#A2A2A2] w-[420px] leading-[28px] flex mx-auto Fontspring-reqular'>Create lasting impact with our innovative designs, blending classic elegance and modern chic to awe and inspire.</div>
                            </div>
                        </div>
                        <img src={tesk} className='flex items-center justify-center mx-auto' />
                    </div>
                </div>
                <div className='testimonials flex items-center justify-center mx-auto mt-[81px]'>
                    <div className='flex flex-row '>
                        <img src={testimonial_line1} />
                        <div className='text-[22px] AltoneTrial-Regular text-black ml-[12px] mr-[12px] '>TESTIMONIALS</div>
                        <img src={testimonial_line2} />
                    </div>
                </div>
                <div className='flex mx-auto items-center justify-center mt-[52px]'>
                    <div className='text-[78px] Gilroy-Regular text-black leading-[25px]'>Our <span className='Gilroy-Bold client-gradient'>Client's</span> are</div>
                </div>
                <div className='text-[78px] Gilroy-Medium text-black flex mx-auto items-center justify-center '>Saying....</div>
                <div className='flex flex-row mx-auto items-center justify-center gap-[141px] mt-[48px]'>
                    <img src={lalitha} />
                    <img src={vivek} />
                    <img src={akilan} />

                </div>

                <div className='mt-[148px] bg-[#FDFDFD] '>
                    <div className='flex'>
                        <div><img src={contact_line} className="w-[142px] ml-[142px]" alt="gradient line" /></div>
                        <div className="text-black text-[22px] mt-[-12px] ml-[14px]">CONTACT US</div>
                    </div>
                    <LandingContactUs />
                </div>
                <Footer />
            </div>
            <div>
                <LandingMobile />
            </div>
        </div>
    );
};