import React, { useState, useEffect } from 'react'
import whitearrow from '../assets/white-arrow.svg'
import landmb from '../assets/bluetik_mobile/landing_bannermb.png'
import bluegradient from '../assets/Bluetik_gradient.svg'
import visionmb from '../assets/bluetik_mobile/visiion_mb.svg'
import missionmb from '../assets/bluetik_mobile/mission_mb.svg'
import valuesmb from '../assets/bluetik_mobile/values_mb.svg'
import bluearrow from '../assets/bluearrow.svg'
import gardientleft from '../assets/bluetik_mobile/Line_mb1.svg'
import gardientright from '../assets/bluetik_mobile/Lineright_mb.svg'
import collectiveleft from '../assets/bluetik_mobile/collective-left.svg'
import collectiveright from '../assets/bluetik_mobile/collective-right.svg'
import img1 from '../assets/bluetik_mobile/wardrobe_mb.svg'
import img2 from '../assets/bluetik_mobile/modular-mb.png'
import img3 from '../assets/bluetik_mobile/bedrrommb.png'
import img4 from '../assets/bluetik_mobile/livingmb.png'
import img5 from '../assets/bluetik_mobile/tvunitmb.png'
import img6 from '../assets/bluetik_mobile/poojaunitmb.png'
import blueprocess from '../assets/bluetik_process.png'
import design from '../assets/design.png'
import plan from '../assets/plan.png'
import live from '../assets/live.png'
import MobileContactForm from './MobileContact'
import NavbarMobile from './navbarMobile'
import { FooterMobile } from './footerMobile'
import Testimobile from './testimonialsMobile'
import whitesofa from '../assets/bluetik_mobile/white-sofa.svg'
import { Link } from 'react-router-dom'
import whatsappIcon from '../assets/bluetik_mobile/whatsapp-icon.svg';  // Import WhatsApp icon image
import instaIcon from '../assets/insta_icon.svg';
import emailIcon from '../assets/email_icon.svg';
// import processbg1 from '../assets/bluetik_mobile/bluetikprocesmb.png'


export const LandingMobile = () => {
    const [homesCount, setHomesCount] = useState(0);
    const [designersCount, setDesignersCount] = useState(0);
    const [citiesCount, setCitiesCount] = useState(0);

    useEffect(() => {
        let homesInterval, designersInterval, citiesInterval;

        const startCounter = (setCount, target, interval) => {
            let count = 0;
            return setInterval(() => {
                count += 1;
                if (count <= target) {
                    setCount(count);
                } else {
                    clearInterval(interval);
                }
            }, 10);
        };

        homesInterval = startCounter(setHomesCount, 100, homesInterval);
        designersInterval = startCounter(setDesignersCount, 5, designersInterval);
        citiesInterval = startCounter(setCitiesCount, 22, citiesInterval);

        return () => {
            clearInterval(homesInterval);
            clearInterval(designersInterval);
            clearInterval(citiesInterval);
        };
    }, []);

    const landingmb_bg = {
        backgroundImage: `url(${landmb})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };


    // const processbg = {
    //     backgroundImage: `url(${processbg1})`,
    //     backgroundSize: 'contain',
    //     backgroundPosition: 'center',
    //     backgroundRepeat: 'no-repeat',
    // };

    return (
        <div className='overflow-x-hidden mobile-only'>
            <div className='bg-transparent z-40 '>
                <NavbarMobile />
            </div>
            <div className='w-full max-w-[430px] h-[968px] bg-red-50 mx-auto z-10 mt-[-127px]' style={landingmb_bg}>

                <div className='text-[30px] text-[#111111] ml-[27px] pt-[322px]  EuclidCircularbold'>COMFORT IN <span className='EuclidCircularMedium'>INTERIORS.</span></div>
                <div className='text-[12px] text-[#111111] ml-[27px] pb-[421px] leading-[15px]'>
                    Experience luxury and leisure with<br /> our carefully chosen interiors
                </div>
                <div className='text-[18px] text-white ml-[27px] mt-[27px] flex items-center'>
                    MEET A DESIGNER<span className='ml-1'><img src={whitearrow} className='w-[15px] h-[15px] ml-[7px]' /></span>
                </div>
                <div className='Gilroy-Medium text-[10px] text-[#A2A2A2] ml-[27px]'>
                    Step into the world of creativity with our<br />
                    designers - your partners in turning visions into reality.
                </div>
                <div className='w-[124px] bg-white h-[2px] ml-[27px] mt-[13px]'></div>
                <div className='text-[18px] text-white ml-[27px] flex items-center mt-[10px]'>
                    VIEW PROJECTS<span className='ml-1'><img src={whitearrow} className='w-[15px] h-[15px] ml-[7px]' /></span>
                </div>
                <div className='Gilroy-Medium text-[10px] text-[#A2A2A2] ml-[27px]'>
                    Commence on a journey of inspiration as you explore our<br />
                    portfolio of meticulously crafted spaces.
                </div>
            </div>



            {/* New WhatsApp Chat Button */}
            <div style={{ position: 'fixed', bottom: '310px', leftt: '20px', marginLeft: '47px', marginTop: '-147px' }}>
                <div className='flex items-center p-2 rounded-full shadow-lg' style={{
                    background: 'rgba(0, 0, 0, 0.19)',
                    backdropFilter: 'blur(1.808px)',
                    webkitBackdropFilter: 'blur(10px)',
                    borderRadius: '42px',
                    padding: '10px'
                }}>
                    <a href="https://wa.me/7619691418 " target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                        <img src={whatsappIcon} alt="WhatsApp" className='w-6 h-6' />
                    </a>
                    <a href="https://www.instagram.com/blue_tik_interiors/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                        <img src={instaIcon} alt="Instagram" className='w-6 h-6' />
                    </a>
                    <a href="mailto:bluetikinteriors@gmail.com" target="_blank" rel="noopener noreferrer" className='flex items-center'>
                        <img src={emailIcon} alt="Email" className='w-6 h-6' />
                    </a>
                </div>
            </div>

            <div className='sec2 bg-[#0C0C0C] w-full mt-[-79px] h-[5494px]'>
                <div className='w-full h-[77px] bg-transparent'></div>
                <img src={bluegradient} className='w-[178px] h-[81px] flex items-center justify-center mx-auto' />
                <div className='Gilroy-Regular text-[16px] w-[365px] leading-[24px] text-[#C0C0C0] flex items-center justify-center mx-auto text-center'>
                    Experience luxury and leisure with our carefully chosen interiors, which put comfort above everything else without sacrificing design. Discover environments where every nook and cranny beckons you to relax and refresh.
                </div>
                <div className='flex flex-row'>
                    <div className='flex flex-col w-[30%]'>
                        <img src={visionmb} className='w-[66.85px] h-[58.25px] ml-[30px] mt-[59px]' />
                        <div className='AltoneTrial-Regular text-[22px] text-[#F0F0F0] ml-[34px] mt-[12px]'>Vision</div>
                    </div>
                    <div className='flex flex-col w-[70%]'>
                        <div className='text-[14px] Fontspring-regular w-[80%] ml-auto mr-auto mt-[84px] text-left text-[#D9D9D9]'>
                            Redefining living with individuality and elegance.
                        </div>
                    </div>
                </div>

                <div className='flex flex-row'>
                    <div className='flex flex-col w-[30%]'>
                        <img src={missionmb} className='w-[66.85px] h-[58.25px] ml-[30px] mt-[59px]' />
                        <div className='AltoneTrial-Regular text-[22px] text-[#F0F0F0] ml-[34px] mt-[12px]'>Mission</div>
                    </div>
                    <div className='flex flex-col w-[70%]'>
                        <div className='text-[14px] Fontspring-regular w-[80%] ml-auto mr-auto mt-[84px] text-left text-[#D9D9D9]'>
                            Innovative designs enrich lives, inspire experiences, one space at a time.
                        </div>
                    </div>
                </div>

                <div className='flex flex-row'>
                    <div className='flex flex-col w-[30%]'>
                        <img src={valuesmb} className='w-[66.85px] h-[58.25px] ml-[30px] mt-[59px]' />
                        <div className='AltoneTrial-Regular text-[22px] text-[#F0F0F0] ml-[34px] mt-[12px]'>Values</div>
                    </div>
                    <div className='flex flex-col w-[70%]'>
                        <div className='text-[14px] Fontspring-regular w-[89%] ml-auto mr-auto mt-[84px] text-[#D9D9D9] text-left'>
                            Integrity, creativity, collaboration, excellence: pillars of exceptional spaces.
                        </div>
                    </div>
                </div>
                <div className='text-[17px] Fontspring-regular leading-[23px] text-[#E2E2E2] flex items-center justify-center mx-auto text-center mt-[74px]'>
                    Delivered with perfection when passion and<br /> creativity meet. Converting spaces into distinctive<br /> contemplations.
                </div>
                <div className='flex mx-auto items-center justify-center mt-[27px]'>
                    <Link to="/aboutusmb"><div className='w-[130px] h-[35px] bg-[#111111] rounded-[21px] text-[13px] text-white flex items-center justify-center'>ABOUT US<span><img src={bluearrow} className='ml-[8px] w-[8px] h-[8px]' /></span></div></Link>
                </div>
                <div className='flex flex-row mt-[147px] items-center justify-center mx-auto'>
                    <div><img src={gardientleft} className='mr-[12px]' /></div>
                    <div className='text-white'>SERVICES</div>
                    <div><img src={gardientright} className='ml-[12px]' /></div>
                </div>
                <div className='text-[15px] Gilroy-Medium flex items-center justify-center mx-auto text-center text-[#EAEAEA] mt-[47px] w-[386px]'>
                    From concept to execution, our team of talented designers will work closely with you to create spaces that are both functional and aesthetically pleasing. Whether it's your home, office, or commercial space, we'll tailor our designs to suit your unique needs and preferences.
                </div>
                <div className='pt-[30px]'>
                    <Link to="/wardropeview"><img src={img1} className='flex items-center justify-center mx-auto mt-[24px]' /></Link>
                    <Link to="/modularview"><img src={img2} className='flex items-center justify-center mx-auto mt-[24px]' /></Link>
                    <img src={img3} className='flex items-center justify-center mx-auto mt-[24px]' />
                    <img src={img4} className='flex items-center justify-center mx-auto mt-[24px]' />
                    <Link to="/tvunitview"> <img src={img5} className='flex items-center justify-center mx-auto mt-[24px]' /></Link>
                    <Link to="/tvunitview"> <img src={img6} className='flex items-center justify-center mx-auto mt-[24px]' /></Link>
                </div>
                <div className='Gilroy-Medium text-[17px] leading-[24px] w-[365px] flex items-center justify-center mx-auto text-center text-[#EAEAEA] mt-[47px]'>
                    We Combines comfort and Refined Designs with the most innovative Technologies
                </div>
                <div className='flex mx-auto items-center justify-center mt-[27px]'>
                    <Link to="/gallerymb"> <div className='w-[130px] h-[35px] bg-[#111111] text-white rounded-[21px] text-[13px] flex items-center justify-center'>VIEW ALL<span><img src={bluearrow} className='ml-[8px] w-[8px] h-[8px] flex items-center justify-center mx-auto mt-[4px]' /></span></div></Link>
                </div>
                <div className='flex flex-row mt-[127px] items-center justify-center mx-auto'>
                    <div><img src={collectiveleft} className='mr-[12px]' /></div>
                    <div className='text-white'>COLLECTIVE TASKS</div>
                    <div><img src={collectiveright} className='ml-[12px]' /></div>
                </div>
                <div className='Gilroy-Regular text-[31px] text-white ml-[30px] mt-[55px] w-[369px] leading-[39px]'>
                    Our team ensures<br /> <span className='linear-excellence Gilroy-Medium'>Unparalleled Excellence</span><br /> in every Homes.
                </div>
                <div className='counter'>
                    <div className='flex flex-row mt-[59px]'>
                        <div className='text-[65px] text-[#ffffff] Gilroy-Medium ml-[30px]'>{homesCount}+</div>
                        <div className='text-[22px] flex items-center justify-center text-[#737373] AltoneTrial-Regular ml-[30px]'>Homes</div>
                    </div>
                    <div className='w-[309px] h-[2px] bg-[#202020] ml-[30px] mt-[30px]'></div>
                    <div className='flex flex-row mt-[59px]'>
                        <div className='text-[65px] text-[#ffffff] Gilroy-Medium ml-[30px]'>{designersCount}+</div>
                        <div className='text-[22px] flex items-center justify-center text-[#737373] AltoneTrial-Regular ml-[30px]'>Designers</div>
                    </div>
                    <div className='w-[309px] h-[2px] bg-[#202020] ml-[30px] mt-[30px]'></div>
                    <div className='flex flex-row mt-[59px]'>
                        <div className='text-[65px] text-[#ffffff] Gilroy-Medium ml-[30px]'>{citiesCount}+</div>
                        <div className='text-[22px] flex items-center justify-center text-[#737373] AltoneTrial-Regular ml-[30px]'>Cities</div>
                    </div>
                    {/* <div className='w-[309px] h-[2px] bg-[#202020] ml-[30px] mt-[30px]'></div> */}
                </div>
                <div className='bluetik-process w-[98%] h-[1658px] flex flex-col items-center justify-center mx-auto  mt-[72px] bg-white rounded-[15px] ' >
                    <img src={blueprocess} className='w-[230px] h-[59px] mt-[87px]' />
                    <div className='text-[18px] text-[#060606] Gilroy-Medium mt-[1px] ml-[140px]'>Process</div>
                    <img src={design} className='mt-[44px]' />
                    <div className='w-[144px] h-[2px] bg-[#B5B5B5] ml-[140px] mt-[20px]'></div>
                    <div className='Fontspring-reqular text-[34px] text-[#0095C3]'>Design</div>
                    <div className='text-[18px] mt-[18px] w-[323px] Fontspring-reqular text-[#A2A2A2] flex items-center justify-center mx-auto text-center'>Create lasting impact with our innovative designs, blending classic elegance and modern chic to awe and inspire.</div>
                    <img src={plan} className='mt-[40px]' />
                    <div className='w-[144px] h-[2px] bg-[#B5B5B5] ml-[140px] mt-[20px]'></div>
                    <div className='Fontspring-reqular text-[34px] text-[#0095C3]'>Plan</div>
                    <div className='text-[18px] mt-[18px] w-[323px] Fontspring-reqular text-[#A2A2A2] flex items-center justify-center mx-auto text-center'>Create lasting impact with our innovative designs, blending classic elegance and modern chic to awe and inspire.</div>
                    <img src={live} className='mt-[40px]' />
                    <div className='w-[144px] h-[2px] bg-[#B5B5B5] ml-[140px] mt-[20px]'></div>
                    <div className='Fontspring-reqular text-[34px] text-[#0095C3]'>Live</div>
                    <div className='text-[18px] mt-[18px] w-[323px] Fontspring-reqular text-[#A2A2A2] flex items-center justify-center mx-auto text-center'>Create lasting impact with our innovative designs, blending classic elegance and modern chic to awe and inspire.</div>
                    <img src={whitesofa} className='mt-[109px]' />
                </div>

            </div>
            <div className='mt-[-87px]'>
                <Testimobile />
            </div>
            <div className=''>
                <MobileContactForm />
            </div>
            <div>
                <FooterMobile />
            </div>
        </div>
    )
}