import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import gallrybg from '../assets/gallery_banner.png';
import singlebhk from '../assets/1bhkdk.jpg';
import doublebhk from '../assets/2bhkdk.jpg';
import villa from '../assets/villadk.jpg';
import wardobe1 from '../assets/wardobe1.svg';
import wardobe2 from '../assets/wardobe2.svg';
import wardobe3 from '../assets/wardobe3.svg';
import wardobe4 from '../assets/wardobe4.svg';
import modula1 from '../assets/modular1.svg';
import modula2 from '../assets/modular2.svg';
import modula3 from '../assets/modular3.svg';
import modula4 from '../assets/modular4.svg';
import bedroom1 from '../assets/bedrooms.png';
import bedroom2 from '../assets/bedrooms.png';
import bedroom3 from '../assets/bedrooms.png';
import bedroom4 from '../assets/bedrooms.png';
import living1 from '../assets/livings.png';
import living2 from '../assets/livings.png';
import living3 from '../assets/livings.png';
import living4 from '../assets/livings.png';
import tvunit1 from '../assets/tvunit1.svg';
import tvunit2 from '../assets/tvunit2.svg';
import tvunit3 from '../assets/tvunit3.svg';
import tvunit4 from '../assets/tvunit4.svg';
import Pooja1 from '../assets/pooja-unit1.jpg';
import Pooja2 from '../assets/pooja-unit2.jpg';
import Pooja3 from '../assets/pooja-unit3.jpg';
import Pooja4 from '../assets/pooja-unit4.jpg';
import LivingView1 from '../assets/LivingView1.jpg';
import LivingView2 from '../assets/LivingView2.jpg';
import LivingView3 from '../assets/LivingView3.jpg';
import LivingView4 from '../assets/LivingView4.jpg';
import GalleryNavbar from './galleryNavbar';
import ImageSlider from './imageSlider';
import galleryrrow from '../assets/galleryarrow.svg';
import whatsappIcon from '../assets/bluetik_mobile/whatsapp-icon.svg';  // Import WhatsApp icon image
import instaIcon from '../assets/insta_icon.svg';
import emailIcon from '../assets/email_icon.svg';
import Footer from './footer';

const Gallery = () => {
    const [isSliderOpen, setIsSliderOpen] = useState(false);
    const [initialSlide, setInitialSlide] = useState(0);
    const [sliderImages, setSliderImages] = useState([]);

    const gallerybg = {
        backgroundImage: `url(${gallrybg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    const handleImageClick = (index, sectionIndex) => {
        const allImages = [
            ...wardrobeImages,
            ...modularKitchenImages,
            ...bedroomImages,
            ...livingRoomImages,
            ...tvUnitImages,
            ...poojaUnitImages,
        ];
        const sectionImages = [wardrobeImages, modularKitchenImages, bedroomImages, livingRoomImages, tvUnitImages, poojaUnitImages];
        const totalIndex = sectionImages.slice(0, sectionIndex).reduce((acc, images) => acc + images.length, 0) + index;

        setInitialSlide(totalIndex);
        setSliderImages(allImages);
        setIsSliderOpen(true);
    };

    const wardrobeImages = [wardobe1, wardobe2, wardobe3, wardobe4];
    const modularKitchenImages = [modula1, modula2, modula3, modula4];
    const bedroomImages = [bedroom1, bedroom2, bedroom3, bedroom4];
    const livingRoomImages = [LivingView1, LivingView2, LivingView3, LivingView4];
    const tvUnitImages = [tvunit1, tvunit2, tvunit3, tvunit4];
    const poojaUnitImages = [Pooja1, Pooja2, Pooja3, Pooja4];

    return (
        <div className='hide-on-mobile'>
            <div className='w-full bg-red-50 relative'>
                <div className='z-10'>
                    <GalleryNavbar />
                </div>
                <div className='w-full h-[1182px] z-40 mt-[-148px]' style={gallerybg}>
                    <div className='absolute bottom-10 right-10 text-white '>
                        <div className='text-[78px] font-bold mr-[48px] Gilroy-Medium pt-[-148px]'>A Showcase of</div>
                        {/* <div className='text-[37px] text-right mr-[48px] mb-[48px] text-[#BFE1EA]'>Bluetik Gallery</div> */}
                        <div className='text-[37px] text-right mr-[48px] mb-[-18px] mt-[-18px] text-[#BFE1EA]  Gilroy-Medium '>
                            Bluetik<span className='Gilroy-Regular ml-[4px]'>Gallery</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ position: 'fixed', bottom: '80px', leftt: '20px', marginLeft: '47px', marginTop: '147px' }}>
                <div className='flex items-center p-2 rounded-full shadow-lg' style={{
                    background: 'rgba(0, 0, 0, 0.19)',
                    backdropFilter: 'blur(1.808px)',
                    webkitBackdropFilter: 'blur(10px)',
                    borderRadius: '42px',
                    padding: '10px'
                }}>
                    <a href="https://wa.me/7619691418" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                        <img src={whatsappIcon} alt="WhatsApp" className='w-6 h-6' />
                    </a>
                    <a href="https://www.instagram.com/blue_tik_interiors/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                        <img src={instaIcon} alt="Instagram" className='w-6 h-6' />
                    </a>
                    <a href="mailto:bluetikinteriors@gmail.com" target="_blank" rel="noopener noreferrer" className='flex items-center'>
                        <img src={emailIcon} alt="Email" className='w-6 h-6' />
                    </a>
                </div>
            </div>
            <div className='choice'>
                <div className='text-center text-black text-[41px] pt-[152px] '>THE CHOICE</div>
                <div className='w-[91%] h-[198px] mx-auto mt-[82px]'>
                    <div className='flex flex-row w-full h-full gap-8'>
                        <div className='flex flex-col w-[33%] h-[144px] '>
                            <img src={singlebhk} />
                        </div>
                        <div className='flex flex-col w-[33%] h-[144px] '>
                            <img src={doublebhk} />
                        </div>
                        <div className='flex flex-col w-[33%] h-[144px] '>
                            <img src={villa} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='galley-section mt-[344px]'>
                <div className='flex ml-[78px]'>
                    <div className='text-[48px] Gilroy-Medium'>Wardrobe</div>
                    <div className='w-[599px] h-[2px] bg-black mt-[45px] ml-[2px] mr-[2px]'></div>
                    <Link to='/wardrope'><div className='w-[108px] h-[34px] border border-black text-black text-center flex items-center justify-center rounded-[23px] mt-[28px] ml-[4px] Gilroy-Medium'> view all<span><img src={galleryrrow} className='ml-[4px]' /></span>
                    </div></Link>

                </div>
                <div className='flex flex-row w-[91%] mx-auto gap-8 mt-[81px]'>
                    {wardrobeImages.map((image, index) => (
                        <div key={index} className='flex flex-col w-[25%] h-[148px] cursor-pointer' onClick={() => handleImageClick(index, 0)}>
                            <img src={image} alt={`Wardrobe ${index + 1}`} />
                        </div>
                    ))}
                </div>

                <div className='flex ml-[78px] mt-[258px]'>
                    <div className='text-[48px] Gilroy-Medium'>Modular Kitchen</div>
                    <div className='w-[599px] h-[2px] bg-black mt-[45px] ml-[2px] mr-[2px]'></div>
                    <Link to='/modularkitchen'><div className='w-[108px] h-[34px] border border-black text-black text-center flex items-center justify-center rounded-[23px] mt-[28px] ml-[4px] Gilroy-Medium'> view all<span><img src={galleryrrow} className='ml-[4px]' /></span>
                    </div></Link>
                </div>
                <div className='flex flex-row w-[91%] mx-auto gap-8 mt-[81px]'>
                    {modularKitchenImages.map((image, index) => (
                        <div key={index} className='flex flex-col w-[25%] h-[148px] cursor-pointer' onClick={() => handleImageClick(index, 1)}>
                            <img src={image} alt={`Modular Kitchen ${index + 1}`} />
                        </div>
                    ))}
                </div>

                <div className='flex ml-[78px] mt-[228px]'>
                    <div className='text-[48px] Gilroy-Medium'>Bedroom</div>
                    <div className='w-[599px] h-[2px] bg-black mt-[45px] ml-[2px] mr-[2px]'></div>
                    <Link to='/bedroom'><div className='w-[108px] h-[34px] border border-black text-black text-center flex items-center justify-center rounded-[23px] mt-[28px] ml-[4px] Gilroy-Medium'> view all<span><img src={galleryrrow} className='ml-[4px]' /></span>
                    </div></Link>
                </div>
                <div className='flex flex-row w-[91%] mx-auto gap-8 mt-[81px]'>
                    {bedroomImages.map((image, index) => (
                        <div key={index} className='flex flex-col w-[25%] h-[148px] cursor-pointer' onClick={() => handleImageClick(index, 2)}>
                            <img src={image} alt={`Bedroom ${index + 1}`} />
                        </div>
                    ))}
                </div>

                <div className='flex ml-[78px] mt-[228px]'>
                    <div className='text-[48px] Gilroy-Medium'>Living Room</div>
                    <div className='w-[599px] h-[2px] bg-black mt-[45px] ml-[2px] mr-[2px]'></div>
                    <Link to='/livingroom'><div className='w-[108px] h-[34px] border border-black text-black text-center flex items-center justify-center rounded-[23px] mt-[28px] ml-[4px] Gilroy-Medium'> view all<span><img src={galleryrrow} className='ml-[4px]' /></span>
                    </div></Link>
                </div>
                <div className='flex flex-row w-[91%] mx-auto gap-8 mt-[81px]'>
                    {livingRoomImages.map((image, index) => (
                        <div key={index} className='flex flex-col w-[25%] h-[148px] cursor-pointer' onClick={() => handleImageClick(index, 3)}>
                            <img src={image} alt={`Living Room ${index + 1}`} />
                        </div>
                    ))}
                </div>

                <div className='flex ml-[78px] mt-[228px]'>
                    <div className='text-[48px] Gilroy-Medium'>Tvunit</div>
                    <div className='w-[599px] h-[2px] bg-black mt-[45px] ml-[2px] mr-[2px]'></div>
                    <Link to='/tvunit'><div className='w-[108px] h-[34px] border border-black text-black text-center flex items-center justify-center rounded-[23px] mt-[28px] ml-[4px] Gilroy-Medium'> view all<span><img src={galleryrrow} className='ml-[4px]' /></span>
                    </div></Link>
                </div>
                <div className='flex flex-row w-[91%] mx-auto gap-8 mt-[81px]'>
                    {tvUnitImages.map((image, index) => (
                        <div key={index} className='flex flex-col w-[25%] h-[148px] cursor-pointer' onClick={() => handleImageClick(index, 4)}>
                            <img src={image} alt={`Tvunit ${index + 1}`} />
                        </div>
                    ))}
                </div>

                <div className='flex ml-[78px] mt-[278px] pb-[48px]'>
                    <div className='text-[48px] Gilroy-Medium'>Pooja Unit</div>
                    <div className='w-[599px] h-[2px] bg-black mt-[45px] ml-[2px] mr-[2px]'></div>
                    <Link to='/poojauniit'><div className='w-[108px] h-[34px] border border-black text-black text-center flex items-center justify-center rounded-[23px] mt-[28px] ml-[4px] Gilroy-Medium'> view all<span><img src={galleryrrow} className='ml-[4px]' /></span>
                    </div></Link>
                </div>
                <div className='flex flex-row w-[91%] mx-auto gap-8 mt-[81px]'>
                    {poojaUnitImages.map((image, index) => (
                        <div key={index} className='flex flex-col w-[25%] h-[148px] cursor-pointer' onClick={() => handleImageClick(index, 5)}>
                            <img src={image} alt={`Pooja Unit ${index + 1}`} />
                        </div>
                    ))}
                </div>
            </div>
            <ImageSlider images={sliderImages} initialSlide={initialSlide} isOpen={isSliderOpen} onClose={() => setIsSliderOpen(false)} />
            <div className='mt-[244px]'>
                <Footer />
            </div>
        </div>
    );
}

export default Gallery;