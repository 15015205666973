import React from 'react'
import logo from '../assets/contact_blurtik.png'
import ContactForm from './form'
import Navbar from './navbar'
import Footer from './footer'
import emaiicon from '../assets/bluetikemail-icon.svg'
import whatsappIcon from '../assets/bluetik_mobile/whatsapp-icon.svg';  // Import WhatsApp icon image
import instaIcon from '../assets/insta_icon.svg';
import emailIcon from '../assets/email_icon.svg';
export const ContactUs = () => {
    return (
        <div className='bg-[#0C0C0C] h-[2789px] hide-on-mobile'>
            <div>
                <Navbar />
            </div>
            <div style={{ position: 'fixed', bottom: '80px', leftt: '20px', marginLeft: '47px', marginTop: '147px' }}>
                <div className='flex items-center p-2 rounded-full shadow-lg' style={{
                    background: 'rgba(0, 0, 0, 0.19)',
                    backdropFilter: 'blur(1.808px)',
                    webkitBackdropFilter: 'blur(10px)',
                    borderRadius: '42px',
                    padding: '10px'
                }}>
                    <a href="https://wa.me/7619691418" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                        <img src={whatsappIcon} alt="WhatsApp" className='w-6 h-6' />
                    </a>
                    <a href="https://www.instagram.com/blue_tik_interiors/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                        <img src={instaIcon} alt="Instagram" className='w-6 h-6' />
                    </a>
                    <a href="mailto:bluetikinteriors@gmail.com" target="_blank" rel="noopener noreferrer" className='flex items-center'>
                        <img src={emailIcon} alt="Email" className='w-6 h-6' />
                    </a>
                </div>
            </div>
            <img src={logo} className='flex items-center justify-center mx-auto pt-[447px]' />
            <div className='text-[24px] text-[#A9A9A9] flex mx-auto items-center justify-center mt-[49px] Gilroy-Regular'>Got a question? We’ve got the answers.</div>
            <div className='text-[24px] text-[#A9A9A9] flex mx-auto items-center justify-center mt-[4px] text-center Gilroy-Regular'>If you would like to speak with us about changing your <br></br> bored homes</div>
            <img src={emaiicon} className='flex mx-auto w-[48px] h-[41px] mt-[18px]' />
            <div className='flex items-center justify-center mx-auto bluelinear text-[24px] tracking-[-0.02em]'>bluetikinteriors.com</div>
            <div className='cts_frm w-[98%] bg-white flex flex-col items-center mx-auto rounded-[23px] h-[998px] mt-[198px]'>
                <div className='w-full flex flex-col items-center'>
                    <div className='text-[17px] text-center text-[#666666] pt-[49px] Gilroy-Regular'>
                        ADDRESS
                    </div>
                    <div className='text-black text-[24px] Gilroy-Regular'>
                        Muthanallur Cross, Bangalore, Karnataka - 562125
                    </div>
                    <div className='text-black text-[24px] Gilroy-Regular'>+91 9600731418</div>
                    <div className='w-[312px] h-[2px] bg-black mt-[54px] '></div>
                    <div className='text-center text-black text-[59px] leading-[71px] mt-[71px] AltoneTrial-Regular'>Let's Transform Your Space <br></br> Together.</div>
                    <div className='mt-[49px] w-[77%] '>
                        <ContactForm />
                    </div>
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}
