import React from 'react'
import NavbarMobile from './navbarMobile'
import wardrobe from '../assets/bluetik_mobile/wRDROBEMB.svg';
import balckarrow from '../assets/bluetik_mobile/blackarrow.svg';

function WardropeMoile() {
    return (
        <div>
            <div>
                <NavbarMobile />
            </div>
            <div className='w-full h-[198px] '>
                <div className='box1'>
                    <div className='Gilroy-Medium text-[27px] text-[#0C0C0C] mt-[101px] ml-[21px]'>Wardrobe</div>
                    <div className='flex flex-row'>
                        <div className='flex items-center justify-center gap-4 ml-[21px] '>
                            <div className='w-[74px] h-[2px] ml-[52px]  bg-black'></div>
                            {/* <div className='w-[89px] h-[34px] bg-white border-black border-2 rounded-[21px] text-[12px] flex items-center justify-center Gilroy-Medium'>View all<span><img src={balckarrow} className='ml-[4px]' /></span></div> */}
                        </div>
                    </div>
                    <div className='gallery'>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                            <img src={wardrobe} />
                            <img src={wardrobe} />
                        </div>
                        <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                            <img src={wardrobe} />
                            <img src={wardrobe} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default WardropeMoile