import React from 'react'
import Bluetik from '../assets/bluetik_mobile/conatct_bluetik.svg';
import ContactForm from './MobileContact';
import ContactFormMbile from './contactFormMobile';
import { FooterMobile } from './footerMobile';
import NavbarMobile from './navbarMobile';
import whatsappIcon from '../assets/bluetik_mobile/whatsapp-icon.svg';  // Import WhatsApp icon image
import instaIcon from '../assets/insta_icon.svg';
import emailIcon from '../assets/email_icon.svg';
export const ContactusMobile = () => {
    return (
        <div>

            <div className='bg-black mobile-only'>
                <div>
                    <NavbarMobile />
                </div>
                <img src={Bluetik} className='pt-[92px]  flex items-center justify-center mx-auto' />
                <div className='Gilroy-Regular text-[14px] text-[#A9A9A9] flex mx-auto text-center items-center justify-center mt-[20px]'>Got a question? We’ve got the answers.</div>
                <div className='Gilroy-Regular text-[14px] text-[#A9A9A9] flex mx-auto text-center items-center justify-center mt-[10px]'>If you would like to speak with us about changing<br></br> your bored homes</div>
                <div className='linear-email text-[17px] AltoneTrial-Regular flex items-center justify-center mx-auto pb-[42px]'>bluetikinteriors.com</div>
                {/* New WhatsApp Chat Button */}
                <div style={{ position: 'fixed', bottom: '310px', leftt: '20px', marginLeft: '47px', marginTop: '-147px' }}>
                    <div className='flex items-center p-2 rounded-full shadow-lg' style={{
                        background: 'rgba(0, 0, 0, 0.19)',
                        backdropFilter: 'blur(1.808px)',
                        webkitBackdropFilter: 'blur(10px)',
                        borderRadius: '42px',
                        padding: '10px'
                    }}>
                        <a href="https://wa.me/7619691418 " target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                            <img src={whatsappIcon} alt="WhatsApp" className='w-6 h-6' />
                        </a>
                        <a href="https://www.instagram.com/blue_tik_interiors/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                            <img src={instaIcon} alt="Instagram" className='w-6 h-6' />
                        </a>
                        <a href="mailto:bluetikinteriors@gmail.com" target="_blank" rel="noopener noreferrer" className='flex items-center'>
                            <img src={emailIcon} alt="Email" className='w-6 h-6' />
                        </a>
                    </div>
                </div>



                <div className='mt-[14px]'>
                    <ContactFormMbile />
                </div>
                <div>
                    <FooterMobile />
                </div>
            </div>
        </div>
    )
}
