import React from 'react'
import servicemb1 from '../assets/bluetik_mobile/servicemb-banner.png';
import residenmb from '../assets/bluetik_mobile/residenmb.png';
import commercialmb from '../assets/bluetik_mobile/commercialmb.png';
import symp from '../assets/title_symbol.svg'
import decorativemb from '../assets/bluetik_mobile/decorativemb.png';
import { FooterMobile } from './footerMobile';
import NavbarMobile from './navbarMobile';
import whatsappIcon from '../assets/bluetik_mobile/whatsapp-icon.svg';  // Import WhatsApp icon image
import instaIcon from '../assets/insta_icon.svg';
import emailIcon from '../assets/email_icon.svg';
const ServiceMb = () => {
    const servicemb = {
        backgroundImage: `url(${servicemb1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    return (
        <div className='bg-[#0C0C0C] h-[1978px] mobile-only'>
            <div>
                <NavbarMobile />
            </div>
            <div className='h-[434px] bg-black w-[95%] mx-auto' style={servicemb}>
                <div className='flex flex-col items-center justify-center h-full'>
                    <div className='text-[40px] craft-gradient text-center EuclidCircular'>Service Craft</div>
                    <div className='text-[18px] Gilroy-Regular text-[#BFBFBF] text-center mt-[21px] w-[366px]'>
                        Elevating spaces with impeccable attention to detail, crafting extraordinary experiences. Beyond the picture, we transcend imagination, setting new standards in design.
                    </div>
                </div>
            </div>

            <img src={residenmb} className='flex mx-auto mt-[24px] w-[383px] h-[184px]' />


            {/* New WhatsApp Chat Button */}
            <div style={{ position: 'fixed', bottom: '310px', leftt: '20px', marginLeft: '47px', marginTop: '-147px' }}>
                <div className='flex items-center p-2 rounded-full shadow-lg' style={{
                    background: 'rgba(0, 0, 0, 0.19)',
                    backdropFilter: 'blur(1.808px)',
                    webkitBackdropFilter: 'blur(10px)',
                    borderRadius: '42px',
                    padding: '10px'
                }}>
                    <a href="https://wa.me/7619691418 " target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                        <img src={whatsappIcon} alt="WhatsApp" className='w-6 h-6' />
                    </a>
                    <a href="https://www.instagram.com/blue_tik_interiors/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target="_blank" rel="noopener noreferrer" className='flex items-center mr-2'>
                        <img src={instaIcon} alt="Instagram" className='w-6 h-6' />
                    </a>
                    <a href="mailto:bluetikinteriors@gmail.com" target="_blank" rel="noopener noreferrer" className='flex items-center'>
                        <img src={emailIcon} alt="Email" className='w-6 h-6' />
                    </a>
                </div>
            </div>




            <div className='w-full h-[414px] 0'>
                <div className='flex flex-row'>
                    <div className='flex flex-col w-[50%] h-[714px]'>
                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Modular kitchen</div>
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Wardrobes</div>
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>TV Unit</div>
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Pooja Unit</div>
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Crockery Unit</div>
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Apartment</div>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col w-[50%] h-[714px]'>
                        <div className='flex flex-row gap-3 items-center mt-[32px]'>
                            <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                            <div className='text-[16px] Gilroy-Medium text-white'>Living Room</div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Dining Room</div>
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Bed Room</div>
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>False Ceiling</div>
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Villa Interiors</div>
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Storage room</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <img src={commercialmb} className='flex mx-auto mt-[24px] w-[383px] h-[184px]' />


            <div className='w-full h-[214px] '>
                <div className='flex flex-row'>
                    <div className='flex flex-col w-[50%] h-[514px]'>
                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Showroom</div>
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Retail Showroom</div>
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Beauty Parlor</div>
                            </div>
                        </div>


                    </div>

                    <div className='flex flex-col w-[50%] h-[514px]'>
                        <div className='flex flex-row gap-3 items-center mt-[32px]'>
                            <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                            <div className='text-[16px] Gilroy-Medium text-white'>Studio</div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Shops</div>
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Boutique</div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <img src={decorativemb} className='flex mx-auto mt-[24px] w-[383px] h-[184px]' />
            <div className='w-full h-[214px] '>
                <div className='flex flex-row'>
                    <div className='flex flex-col w-[50%] h-[514px]'>
                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Vertical Gardening</div>
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Furniture’s</div>
                            </div>
                        </div>




                    </div>

                    <div className='flex flex-col w-[50%] h-[514px]'>
                        <div className='flex flex-row gap-3 items-center mt-[32px]'>
                            <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                            <div className='text-[16px] Gilroy-Medium text-white'>Hand Curving</div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='flex flex-row gap-3 items-center justify-center mt-[32px]'>
                                <img src={symp} className='w-[15px] h-[19px] ml-[49px]' />
                                <div className='text-[16px] Gilroy-Medium text-white'>Wall Decors</div>
                            </div>
                        </div>




                    </div>
                </div>
            </div>
            <div className='mt-[-147px]'>
                <FooterMobile />
            </div>
        </div>
    )
}

export default ServiceMb