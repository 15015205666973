


import React from 'react'
import Navbar from './navbar'
import GalleryNavbar from './galleryNavbar'
import LivingView1 from '../assets/LivingView1.jpg';
import LivingView2 from '../assets/LivingView2.jpg';
import LivingView3 from '../assets/LivingView3.jpg';
import LivingView4 from '../assets/LivingView4.jpg';
import LivingView5 from '../assets/LivingView5.jpg';
import LivingViewsix from '../assets/LivingViewsix.jpg';
import LivingView7 from '../assets/LivingView7.jpg';
import LivingView8 from '../assets/LivingView8.jpg';
import LivingView9 from '../assets/LivingView9.jpg';
import LivingView10 from '../assets/LivingView10.jpg';
import LivingView11 from '../assets/LivingView11.jpg';
import LivingView12 from '../assets/LivingView12.jpg';
import wardobe1 from '../assets/wardrobe.jpg';
import modula1 from '../assets/modulakitchen.jpg';
import bedroom1 from '../assets/bedrooms.png';
import living1 from '../assets/livings.png';
import Footer from './footer';

export const LivingRoom = () => {
    return (
        <div>
            <div>
                <GalleryNavbar />
            </div>
            <div className='text-[80px] Gilroy-Medium text-[#0C0C0C] ml-[134px] mt-[84px]'>LivingRoom</div>
            <div className='w-[124px] h-[2px] bg-black  ml-[374px]'></div>
            <div className='boxes mt-[74px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={LivingView1} />
                    <img src={LivingView2} />
                    <img src={LivingView3} />
                    <img src={LivingView4} />
                </div>
            </div>

            <div className='boxes mt-[74px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={LivingView5} />
                    <img src={LivingViewsix} />
                    <img src={LivingView7} />
                    <img src={LivingView8} />
                </div>
            </div>


            <div className='boxes mt-[74px] pb-[77px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={LivingView9} />
                    <img src={LivingView10} />
                    <img src={LivingView11} />
                    <img src={LivingView12} />
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}
