import React, { useEffect, useState } from 'react';

const animateValue = (start, end, duration, setValue) => {
    let startTimestamp = null;
    const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        setValue(Math.floor(progress * (end - start) + start));
        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
};

const Stats = () => {
    const [homes, setHomes] = useState(0);
    const [designers, setDesigners] = useState(0);
    const [cities, setCities] = useState(0);

    useEffect(() => {
        animateValue(0, 100, 2000, setHomes);
        animateValue(0, 5, 2000, setDesigners);
        animateValue(0, 22, 2000, setCities);
    }, []);

    return (
        <div className="flex justify-center items-center  bg-[#0C0C0C] text-white pb-[84px] ">
            <div className="flex space-x-16">
                <div className="text-center">
                    <p className="text-[121px] text-white Gilroy-Medium">{homes}+</p>
                    <p className="text-[21px] mt-2 text-[#737373] AltoneTrial-Regular">Homes</p>
                </div>
                <div className='w-[2px] h-[92px] bg-white m-[84px]'></div>
                <div className="text-center">
                    <p className="text-[121px] text-white Gilroy-Medium">{designers}+</p>
                    <p className="text-[21px] mt-2 text-[#737373] AltoneTrial-Regular">Designers</p>
                </div>
                <div className='w-[2px] h-[92px] bg-white m-[84px]'></div>

                <div className="text-center">
                    <p className="text-[121px] text-white Gilroy-Medium">{cities}+</p>
                    <p className="text-[21px] mt-2 text-[#737373] AltoneTrial-Regular">Cities</p>
                </div>
            </div>
        </div>
    );
};

export default Stats;