import React from 'react';
import imagePath from '../assets/contact_frame.png'; // replace with your actual image path

const LandingContactUs = () => {
    return (
        <div className="flex w-[89%] mx-auto my-10 bg-[#FDFDFD]  shadow-lg rounded-lg overflow-hidden mt-[149px]">
            <div className="relative flex-1">
                <img src={imagePath} alt="Architecture" className="w-full h-full object-cover" />
                {/* Uncomment and replace with your actual WhatsApp icon path
                <button className="absolute bottom-4 left-4 bg-green-500 text-white flex items-center px-4 py-2 rounded">
                    <img src={whatsappIcon} alt="WhatsApp" className="w-5 h-5 mr-2" />
                    Let's Chat
                </button>
                */}
            </div>
            <div className="flex-1 p-10">
                <h2 className="text-[59px] mb-6 leading-[81px] Gilroy-Medium">Let's Transform Your Space Together.</h2>
                <form>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <input type="text" name="name" placeholder="NAME" className="border-b-2 p-2 focus:outline-none" required />
                        <input type="email" name="email" placeholder="EMAIL" className="border-b-2 p-2 focus:outline-none" required />
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-4 mt-[72px]">
                        <input type="tel" name="phone" placeholder="PHONE NUMBER" className="border-b-2 p-2 focus:outline-none" required />
                        <input type="text" name="city" placeholder="CITY" className="border-b-2 p-2 focus:outline-none" required />
                    </div>
                    <div className="mb-4 mt-[72px]">
                        <textarea name="message" placeholder="MESSAGE" className="border p-2 rounded w-full h-[247px] focus:outline-none" required></textarea>
                    </div>
                    <div className="flex justify-end">
                        <button type="submit" className="bg-black text-white px-4 py-2 rounded-[22px] flex items-center">
                            SUBMIT
                            <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LandingContactUs;