import React from 'react'
import NavbarMobile from './navbarMobile'
import PoojaView1 from '../assets/PoojaView1.jpg';
import PoojaView2 from '../assets/PoojaView2.jpg';
import PoojaView3 from '../assets/PoojaViewthree.jpg';
import PoojaView4 from '../assets/PoojaView4.jpg';
import PoojaView5 from '../assets/PoojaView5.jpg';
import PoojaViewsix from '../assets/PoojaViewsix.jpg';
import PoojaView7 from '../assets/PoojaView7.jpg';
import PoojaView8 from '../assets/PoojaView8.jpg';
import PoojaView9 from '../assets/PoojaView9.jpg';
import PoojaView10 from '../assets/PoojaViewten.jpg';
import PoojaView11 from '../assets/PoojaView11.jpg';
import PoojaView12 from '../assets/PoojaView12.jpg';
import { FooterMobile } from './footerMobile';

// import balckarrow from '../assets/bluetik_mobile/blackarrow.svg';

function PoojaView() {
    return (
        <div>
            <div>
                <div>
                    <NavbarMobile />
                </div>
                <div className='w-full h-[198px] '>
                    <div className='box1'>
                        <div className='Gilroy-Medium text-[27px] text-[#0C0C0C] mt-[101px] ml-[21px]'>Tv Unit</div>
                        <div className='flex flex-row'>
                            <div className='flex items-center justify-center gap-4 ml-[21px] '>
                                <div className='w-[74px] h-[2px] ml-[52px]  bg-black'></div>
                                {/* <div className='w-[89px] h-[34px] bg-white border-black border-2 rounded-[21px] text-[12px] flex items-center justify-center Gilroy-Medium'>View all<span><img src={balckarrow} className='ml-[4px]' /></span></div> */}
                            </div>
                        </div>
                        <div className='gallery'>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                                <img src={PoojaView1} />
                                <img src={PoojaView2} />
                            </div>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                                <img src={PoojaView3} />
                                <img src={PoojaView4} />
                            </div>
                        </div>

                        <div className='gallery'>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                                <img src={PoojaView5} />
                                <img src={PoojaViewsix} />
                            </div>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                                <img src={PoojaView7} />
                                <img src={PoojaView8} />
                            </div>
                        </div>


                        <div className='gallery'>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                                <img src={PoojaView9} />
                                <img src={PoojaView10} />
                            </div>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                                <img src={PoojaView11} />
                                <img src={PoojaView12} />
                            </div>
                        </div>



                    </div>
                </div>
                <div className='mt-[1178px]'>
                    <FooterMobile />
                </div>
            </div>
        </div>
    )
}

export default PoojaView