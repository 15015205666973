import React from 'react';
import bluearrow from '../assets/blue_arrows.svg'
import Footer from './footer';
const ContactForm = () => {
    return (
        <div className="w-[98%] bg-white mx-auto rounded-[23px] p-6">
            <div className="flex flex-wrap justify-between">
                <div className="flex flex-col min-w-[300px] m-2">
                    <label htmlFor="name" className="mb-1 text-xs font-bold text-gray-600 AltoneTrial-Regular">NAME</label>
                    <input type="text" id="name" className="p-2 border-b border-gray-300 focus:outline-none focus:border-black" />
                </div>
                <div className="flex flex-col min-w-[300px] m-2">
                    <label htmlFor="email" className="mb-1 text-xs font-bold text-gray-600 AltoneTrial-Regular">EMAIL</label>
                    <input type="email" id="email" className="p-2 border-b border-gray-300 focus:outline-none focus:border-black" />
                </div>
                <div className="flex flex-col min-w-[300px] m-2">
                    <label htmlFor="phone" className="mb-1 text-xs font-bold text-gray-600 AltoneTrial-Regular">PHONE NUMBER</label>
                    <input type="tel" id="phone" className="p-2 border-b border-gray-300 focus:outline-none focus:border-black" />
                </div>
                <div className="flex flex-col min-w-[300px] m-2">
                    <label htmlFor="city" className="mb-1 text-xs font-bold text-gray-600 AltoneTrial-Regular">CITY</label>
                    <input type="text" id="city" className="p-2 border-b border-gray-300 focus:outline-none focus:border-black" />
                </div>
            </div>
            <div className="flex flex-col w-full m-2 mt-[47px]">
                <label htmlFor="message" className="mb-1 text-xs font-bold text-gray-600 AltoneTrial-Regular">MESSAGE</label>
                <textarea id="message" className="p-2 border border-gray-300 min-h-[200px] max-h-[200px] resize-none focus:outline-none focus:border-black rounded"></textarea>
            </div>
            <div className="flex justify-center w-full mt-5">
                <button type="submit" className="flex items-center px-6 py-3 text-white bg-black rounded-3xl hover:bg-gray-800 AltoneTrial-Regular">
                    SUBMIT
                    <span className='ml-2'><img src={bluearrow} alt="arrow" /></span>
                </button>
            </div>

        </div>
    );
};

export default ContactForm;