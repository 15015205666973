import React from 'react'
import NavbarMobile from './navbarMobile'
import tvview1 from '../assets/tvview1.jpg';
import tvview2 from '../assets/tvview2.jpg';
import tvview3 from '../assets/tvview3.jpg';
import tvview4 from '../assets/tvview4.jpg';
import tvview5 from '../assets/tvview5.jpg';
import tvviewsix from '../assets/tvviewsix.jpg';
import tvview7 from '../assets/tvview7.jpg';
import tvview8 from '../assets/tvview8.jpg';
import tvview9 from '../assets/tvview9.jpg';
import tvview10 from '../assets/tvview10.jpg';
import tvview11 from '../assets/tvview11.jpg';
import tvview12 from '../assets/tvview12.jpg';
import { FooterMobile } from './footerMobile';

// import balckarrow from '../assets/bluetik_mobile/blackarrow.svg';

function TvView() {
    return (
        <div>
            <div>
                <div>
                    <NavbarMobile />
                </div>
                <div className='w-full h-[198px] '>
                    <div className='box1'>
                        <div className='Gilroy-Medium text-[27px] text-[#0C0C0C] mt-[101px] ml-[21px]'>Tv Unit</div>
                        <div className='flex flex-row'>
                            <div className='flex items-center justify-center gap-4 ml-[21px] '>
                                <div className='w-[74px] h-[2px] ml-[52px]  bg-black'></div>
                                {/* <div className='w-[89px] h-[34px] bg-white border-black border-2 rounded-[21px] text-[12px] flex items-center justify-center Gilroy-Medium'>View all<span><img src={balckarrow} className='ml-[4px]' /></span></div> */}
                            </div>
                        </div>
                        <div className='gallery'>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                                <img src={tvview1} />
                                <img src={tvview2} />
                            </div>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                                <img src={tvview3} />
                                <img src={tvview4} />
                            </div>
                        </div>

                        <div className='gallery'>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                                <img src={tvview5} />
                                <img src={tvviewsix} />
                            </div>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                                <img src={tvview7} />
                                <img src={tvview8} />
                            </div>
                        </div>


                        <div className='gallery'>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[41px]'>
                                <img src={tvview9} />
                                <img src={tvview10} />
                            </div>
                            <div className='flex flex-row gap-5 items-center justify-center mx-auto mt-[21px]'>
                                <img src={tvview11} />
                                <img src={tvview12} />
                            </div>
                        </div>



                    </div>
                </div>
                <div className='mt-[1178px]'>
                    <FooterMobile />
                </div>
            </div>
        </div>
    )
}

export default TvView