import React from 'react';
import bluearrow from '../assets/bluearrow.svg'
import gardientright from '../assets/bluetik_mobile/Lineright_mb.svg'

const ContactFormMbile = () => {
    return (
        <div className="min-h-screen flex items-center justify-center ">
            <form className="bg-white p-6 rounded-lg  w-full max-w-sm">
                <div className='Gilroy-Regular text-[15px] text-[#666666] flex items-center justify-center mx-auto'>ADDRESS</div>
                <div className='Gilroy-Regular text-[18px] text-black flex items-center justify-center text-center mx-auto'>Muthanallur Cross, Bangalore, Karnataka - 562125</div>
                <div className='Gilroy-Regular text-[18px] text-black flex items-center justify-center text-center mx-auto'>+91 9600731418</div>
                <div className='w-[209px] h-[2px] bg-black flex items-center justify-center text-center mx-auto mt-[17px] mb-[17px]'></div>
                <h2 className="text-[24px] w-[332px] AltoneTrial-Regular text-center mt-[14px] leading-[30px]">Let's Transform Your Space Together.</h2>

                <div className="mb-4 mt-[34px]">
                    <label className="block text-[15px] font-bold text-[#000000] uppercase AltoneTrial-Regular" htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className="mt-1 block w-full border-0 border-b border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-[15px] font-bold text-black uppercase AltoneTrial-Regular" htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        className="mt-1 block w-full border-0 border-b border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-[15px] font-bold text-black uppercase AltoneTrial-Regular" htmlFor="phone">Phone Number</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className="mt-1 block w-full border-0 border-b border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-[15px] font-bold text-black uppercase AltoneTrial-Regular" htmlFor="city">City</label>
                    <input
                        type="text"
                        id="city"
                        name="city"
                        className="mt-1 block w-full border-0 border-b border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>

                <div className="mb-6">
                    <label className="block text-[15px] font-bold text-black uppercase AltoneTrial-Regular" htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        rows="4"
                        className="mt-1 block w-full h-[279px] border border-gray-300 rounded-[22px] focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    ></textarea>
                </div>

                <button
                    type="submit"
                    className="py-2 flex items-center justify-center px-4 border border-transparent rounded-[17px] shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    style={{ width: 'fit-content' }}
                >
                    Submit <span><img src={bluearrow} className='ml-[8px] w-[7px] h-[7px]' /></span>
                </button>
            </form>
        </div>
    );
}

export default ContactFormMbile;