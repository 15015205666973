import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; // For hamburger and close icons
import sitelogo from '../assets/LOGO.svg';
import emailgradient from '../assets/bluetik_mobile/emailgradient.svg';

const NavbarMobile = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav
            className={`glassbg p-4 flex items-center justify-between fixed top-0 left-0 right-0 z-50
        ${isOpen ? 'rounded-none' : 'rounded-bl-[20px] rounded-br-[20px]'} 
        transition-all duration-300 ease-in-out`}>
            <div className="flex items-center ml-[44px]">
                <Link to="/"><img src={sitelogo} className='w-[194px] h-[34px] ml-[-34px]' alt="Site Logo" /></Link>
            </div>
            <div className="hidden lg:flex space-x-4">
                <Link to="/" className="text-white text-[21px] text-left hover:bg-gray-700 hover:text-white px-5 py-9 rounded-md text-sm font-medium navy ">HOME</Link>
                <Link to="/aboutus" className="text-white text-[21px] hover:bg-gray-700 hover:text-white px-5 py-9 rounded-md text-sm font-medium">ABOUT US</Link>
                <Link to="/gallery" className="text-white text-[21px] hover:bg-gray-700 hover:text-white px-5 py-9 rounded-md text-sm font-medium">GALLERY</Link>
                <Link to="/service" className="text-white text-[21px] hover:bg-gray-700 hover:text-white px-5 py-9 rounded-md text-sm font-medium">SERVICE</Link>
                <Link to="/contactus" className="text-white text-[21px] hover:bg-gray-700 hover:text-white px-5 py-9 rounded-md text-sm font-medium">CONTACT US</Link>
            </div>
            <div className="lg:hidden flex items-center">
                <button onClick={toggleMenu} className="text-white text-2xl">
                    {isOpen ? <FaTimes /> : <FaBars />}
                </button>
            </div>
            <div
                className={`lg:hidden absolute top-16 right-0 bg-[#0C0C0C] w-full 
                ${isOpen ? 'block' : 'hidden'} 
                transition-all duration-300 ease-in-out`}>
                <div className="flex flex-col h-[714px]">
                    <Link to="/landmb" onClick={toggleMenu} className="text-white text-[21px] hover:bg-gray-700 hover:text-white px-5 py-4 rounded-md text-sm font-medium mt-[24px] ml-[-344px]">HOME</Link>
                    <Link to="/aboutusmb" onClick={toggleMenu} className="text-white text-left text-[21px] hover:bg-gray-700 hover:text-white px-5 py-4 rounded-md text-sm Gilroy-Regular">ABOUT US</Link>
                    <Link to="/gallerymb" onClick={toggleMenu} className="text-white text-left text-[21px] hover:bg-gray-700 hover:text-white px-5 py-4 rounded-md text-sm Gilroy-Regular ">GALLERY</Link>
                    <Link to="/servicemb" onClick={toggleMenu} className="text-white text-left text-[21px] hover:bg-gray-700 hover:text-white px-5 py-4 rounded-md text-sm Gilroy-Regular">SERVICE</Link>
                    <Link to="/contactmb" onClick={toggleMenu} className="text-white text-left text-[21px] hover:bg-gray-700 hover:text-white px-5 py-4 rounded-md text-sm Gilroy-Regular">CONTACT US</Link>
                    <div className='mt-[118px]'>
                        <div className='flex items-center justify-center mx-auto'><img src={emailgradient} /></div>
                        <div className='text-white flex items-center justify-center linear-menu text-[22px] Gilroy-Medium'>bluetikinteriors.com</div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default NavbarMobile;