import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Custom Next Arrow
const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
            onClick={onClick}
        />
    );
};

// Custom Previous Arrow
const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
            onClick={onClick}
        />
    );
};

const ImageSlider = ({ images, initialSlide, isOpen, onClose }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: initialSlide,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    if (!isOpen) return null;

    const handleOutsideClick = (e) => {
        if (e.target.id === 'sliderOverlay') {
            onClose();
        }
    };

    return (
        <div
            id="sliderOverlay"
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={handleOutsideClick}
        >
            <div className="relative bg-white p-8 rounded-lg max-w-3xl w-full" onClick={(e) => e.stopPropagation()}>
                <button className="absolute top-2 right-2 text-2xl" onClick={onClose}>&times;</button>
                <Slider {...settings}>
                    {images.map((image, index) => (
                        <div key={index}>
                            <img src={image} alt={`Slide ${index}`} className="w-full h-auto" />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default ImageSlider;