import React from 'react'
import ftrlogo from '../assets/Bluetik Interiors.svg'
import facebbok from '../assets/fb.svg'
import insta from '../assets/insta.svg'
import whastapp from '../assets/whts.svg'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='w-full bg-[#0C0C0C] h-[741px] overflow-x-hidden'>
            <div className='flex flex-row pt-[182px]'>
                <div className='flex flex-col w-[70%] h-[49px] bg-[#0C0C0C]'></div>
                <div className='flex flex-col w-[40%] h-[49px] bg-[#0C0C0C]'>
                    <div className='flex flex-row gap-9 '>
                        <Link to="/" ><div className='text-[21px] text-white Gilroy-Regular'>HOME</div></Link>
                        <Link to="/aboutus"><div className='text-[21px] text-white Gilroy-Regular'>ABOUT US</div></Link>
                        <Link to="/gallery"><div className='text-[21px] text-white Gilroy-Regular'>GALLERY</div></Link>
                        <Link to="/service"><div className='text-[21px] text-white Gilroy-Regular'>SERVICE</div></Link>
                        <Link to="/contactus"><div className='text-[21px] text-white Gilroy-Regular'>CONTACT US</div></Link>
                    </div>
                    <div className='flex flex-row mt-[85px]'>
                        <div className='flex flex-col w-[49%] h-[98px]'>
                            <div className='text-[20px] text-[#666666]'>ADDRESS</div>
                            <div className='text-[20px] text-white'>Muthanallur Cross, Bangalore,<br></br> Karnataka - 562125</div>
                        </div>
                        <div className='flex flex-col w-[49%] h-[97px]'>
                            <div className='text-[20px] text-[#666666]'>CONTACT</div>
                            <div className='text-[20px] text-white'>Bluetikinteriors@gmail.com<br></br>+91 9600731418</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex  mt-[250px] ml-[171px]'>
                <img src={ftrlogo} alt="Bluetik Interiors Logo" />
            </div>
            <div className='flex flex-row mt-[22px] ml-[171px] pb-[42px]'>

                <div className='flex text-[35px] text-[#9D9D9D] Gilroy-Regular tracking-[-0.02em]'>The Complete Interior Solutions</div>
                <div className='flex w-[821px] h-[2px] bg-white mt-[34px] ml-[14px]'></div>
                <div className='flex flex-row gap-4 ml-[22px] mt-[22px]'>
                    <div><img src={facebbok} /></div>
                    <div><img src={insta} /></div>
                    <div><img src={whastapp} /></div>

                </div>
            </div>
            <div className='flex flex-row bg-[#9D9D9D] h-[51px] items-center justify-center mx-auto'>
                <div className='flex flex-col w-[51%] h-[51px]   items-center justify-center'>
                    <div className='text-[20px] text-[#0C0C0C] ml-[151px]'>2024 Bluetik Interiors. All Rights Reserved. Site by SIRA</div>
                </div>
                <div className='flex flex-col w-[51%] h-[51px]  items-center justify-center'>
                    <div className='text-[20px] text-[#0C0C0C] ml-[411px]'>Privacy Policy <span>Terms and Condition</span></div>
                </div>
            </div>
        </div>
    )
}

export default Footer