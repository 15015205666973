


import React from 'react'
import Navbar from './navbar'
import GalleryNavbar from './galleryNavbar'
import tvunit1 from '../assets/tvunit1.svg';
import tvunit2 from '../assets/tvunit2.svg';
import tvunit3 from '../assets/tvunit3.svg';
import tvunit4 from '../assets/tvunit4.svg';
import tvunit5 from '../assets/tvunit5.svg';
import tvunit6 from '../assets/tvunit6.svg';
import tvunit7 from '../assets/tvunit7.svg';
import tvunit8 from '../assets/tvunit8.svg';
import tvunit9 from '../assets/tvunit9.svg';
import tvunit10 from '../assets/tvunit10.svg';
import tvunit11 from '../assets/tvunit11.svg';
import tvunit12 from '../assets/tvunit12.svg';
import Footer from './footer';

export const TvUnit = () => {
    return (
        <div>
            <div>
                <GalleryNavbar />
            </div>
            <div className='text-[80px] Gilroy-Medium text-[#0C0C0C] ml-[134px] mt-[84px]'>Tv Unit</div>
            <div className='w-[124px] h-[2px] bg-black  ml-[244px]'></div>
            <div className='boxes mt-[74px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={tvunit1} />
                    <img src={tvunit2} />
                    <img src={tvunit3} />
                    <img src={tvunit4} />
                </div>
            </div>

            <div className='boxes mt-[74px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={tvunit5} />
                    <img src={tvunit6} />
                    <img src={tvunit7} />
                    <img src={tvunit8} />
                </div>
            </div>


            <div className='boxes mt-[74px] pb-[77px]'>
                <div className='flex flex-row items-center justify-center gap-9'>
                    <img src={tvunit9} />
                    <img src={tvunit10} />
                    <img src={tvunit11} />
                    <img src={tvunit12} />
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}
